import { Link } from "react-router-dom";
import { ReactComponent as Xicon } from "../../assets/Images/x-icon.svg";
import Image from "../../Ui/Image";
import { ReactComponent as RatingIcon } from "../../assets/Images/rating-icon.svg";
import { StreamCountFormatter, categoryFormatter, contentNameFormtter, genreBreakFormatter } from "../../Util/FormatData/Formatter";

const GridViewEightCarouselCardMobile = ({ publishCT }) => {
  const link = `/${categoryFormatter(publishCT?.style)}/${genreBreakFormatter(
    publishCT?.primaryGenre
  )}/${contentNameFormtter(publishCT?.name)}/${publishCT?.id}`;

  let rating;
  if (publishCT?.review && publishCT?.reviewersCount) {
    rating = (publishCT?.review / publishCT?.reviewersCount).toFixed(1);
  }

  return (
    <div className="grid-view-8-carousel-card-eight">
      <Link to={link} />
      <div className="img-wrapper">
        <Image src={publishCT?.bannerSquare?.md} alt={publishCT?.banner1Alts} />
        <div className="x-icon">
          <Xicon />
        </div>
      </div>
      <h3 className="title">{publishCT?.name}</h3>
      <p className="rating-plays">
        {rating && (
          <>
            <RatingIcon /> {rating} |
          </>
        )}{" "}
        {StreamCountFormatter(publishCT?.streams)} Plays
      </p>
      <p className="about">{publishCT?.about}</p>
    </div>
  );
};

export default GridViewEightCarouselCardMobile;
