import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { usePlayerContext } from "../../Context/PlayerContext";

const speeds = [0.5, 1.0, 1.25, 1.5, 2.0];

const SpeedChangeHandler = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [speed, setspeed] = useState(1);

  // const currentSong = usePlayerStore((state) => state.currentSong);
  // const audioRef = usePlayerStore((state) => state.audioRef);

  const { currentSong, audioRef } = usePlayerContext();
  useEffect(() => {
    setspeed(1);
  }, [currentSong?.songId]);

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleSelectSpeed = (number) => {
    audioRef.current.playbackRate = number;
    setspeed(number);
    handleClosePopover();
  };

  return (
    <>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          padding: "0px",
          zIndex: 100000,
          ".MuiPaper-elevation, .MuiPaper-root, .MuiPopover-paper, .css-3bmhjh-MuiPaper-root-MuiPopover-paper":
            {
              background: " #383838",
              color: "#B8B8B8",
              fontfamily: "Roboto",
              fontSize: "1rem",
              fontStyle: "normal",
              fontWeight: "500",
              width: "6rem",
              textAlign: "center",
              // paddingLeft: "15px",
              cursor: "pointer",
            },
        }}
      >
        <List>
          {speeds.map((number, index) => (
            <ListItem key={index} onClick={() => handleSelectSpeed(number)}>
              <ListItemText primary={`${number}x`} />
            </ListItem>
          ))}
        </List>
      </Popover>

      <IconButton
        sx={{
          color: "#B8B8B8",
          padding: 0,
          margin: 0,
          fontSize: "1.2rem",
          fontfamily: "Roboto",
        }}
        onClick={handleOpenPopover}
        className="gta-audioSpeedButton"
      >
        <span style={{ fontfamily: "Poppins" }}>{speed}x</span>
      </IconButton>
    </>
  );
};

export default SpeedChangeHandler;
