import { Link } from "react-router-dom";
import { ReactComponent as Xicon } from "../../assets/Images/x-icon.svg";
import {
  StreamCountFormatter,
  categoryFormatter,
  contentNameFormtter,
  genreBreakFormatter,
} from "../../Util/FormatData/Formatter";
import Image from "../../Ui/Image";
import { ReactComponent as RatingIcon } from "../../assets/Images/rating-icon.svg";
import SubscribeButton from "../../Ui/SubscribeButton";
import ResumePlayButtonHome from "../../Ui/ResumePlayButtonHome";

const PortraitCarouselCard = ({ publishCT, carouselName }) => {
  const link = `/${categoryFormatter(publishCT?.style)}/${genreBreakFormatter(
    publishCT?.primaryGenre
  )}/${contentNameFormtter(publishCT?.name)}/${publishCT?.id}`;

  let rating;
  if (publishCT?.review && publishCT?.reviewersCount) {
    rating = (publishCT?.review / publishCT?.reviewersCount).toFixed(1);
  }

  return (
    <div className={`potrait-carousel-card `}>
      <div className="img-wrapper">
        <Link to={link} state={{ carouselName: carouselName }} />
        <Image
          src={publishCT?.bannerRectangle?.md || publishCT?.bannerRectangle?.sm}
          alt={publishCT?.banner1Alt || publishCT?.about}
        />
        {publishCT?.contentType === "PUBLISH_RECORD" && (
          <div className="x-icon">
            <Xicon color="red" background="red" />
          </div>
        )}
      </div>
      <div className="potrait-card-content">
        <h2 className="title">{publishCT?.name}</h2>
        <p className="rating-plays">
          {rating && (
            <>
              <RatingIcon /> {rating} |
            </>
          )}{" "}
          {StreamCountFormatter(publishCT?.streams)} Plays
        </p>
        <p className="about">{publishCT?.about}</p>
        <div className="btns">
          <ResumePlayButtonHome
            parentData={publishCT}
            carouselName={carouselName}
            className={"play-button"}
          >
            Play
          </ResumePlayButtonHome>
          <SubscribeButton data={publishCT} childrenType={"ICON"} />
        </div>
      </div>
    </div>
  );
};

export default PortraitCarouselCard;
