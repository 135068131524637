import { Navigate, Route, Routes } from "react-router-dom";
import { Suspense } from "react"; // Import Suspense for lazy loading
import React from "react";
import EightLoader from "../components/Backdrop/EightLoader";
import HomePage from "../pages/Home/Home";
import MobileMusicPlayerModal from "../components/Modal/MobileMusicPlayer";

// Lazy loading for each route component
const ViewAllShows = React.lazy(() => import("../pages/shows/ViewAllShows"));
const SearchPage = React.lazy(() => import("../pages/search/SearchPage"));
const GenreListingPage = React.lazy(() =>
  import("../pages/shows/GenreListingPage")
);
const GenrePage = React.lazy(() => import("../pages/shows/GenrePage"));
const Open = React.lazy(() => import("../pages/Open"));
const ShowDetails = React.lazy(() =>
  import("../pages/ShowDetails/ShowDetails")
);

const Router = ({ searchInput }) => {
  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <Suspense fallback={<EightLoader />}>
            <HomePage />
          </Suspense>
        }
      />
      <Route
        exact
        path="/open"
        element={
          <Suspense fallback={<EightLoader />}>
            <Open />
          </Suspense>
        }
      />
      <Route
        exact
        path="/search"
        element={
          <Suspense fallback={<EightLoader />}>
            <SearchPage searchInput={searchInput} />
          </Suspense>
        }
      />
      <Route
        exact
        path="/mobile-player"
        element={
          <Suspense fallback={<EightLoader />}>
            <MobileMusicPlayerModal  />
          </Suspense>
        }
      />
      <Route
        exact
        path="/:id"
        element={
          <Suspense fallback={<EightLoader />}>
            <GenrePage />
          </Suspense>
        }
      />
      <Route
        exact
        path="/:type/:value"
        element={
          <Suspense fallback={<EightLoader />}>
            <GenreListingPage />
          </Suspense>
        }
      />
      <Route
        exact
        path="/show/view-all/:id"
        element={
          <Suspense fallback={<EightLoader />}>
            <ViewAllShows />
          </Suspense>
        }
      />
      <Route
        exact
        path="/:type/:genre/:name/:id"
        element={
          <Suspense fallback={<EightLoader />}>
            <ShowDetails />
          </Suspense>
        }
      />
      <Route exact path="/*" element={<Navigate to={"/"} />} />

      <Route path="*" element={<Navigate to="/" replace={true} />} />
    </Routes>
  );
};

export default Router;
