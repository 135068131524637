import axios from "axios";

export const getContinueListening = async (authCtx) => {
  return await axios.get(
    `${process.env.REACT_APP_EIGHT_PUBLISH_CONTENT_API}/api/audio/library/continue/listening`,
    {
      headers: {
        Authorization: `Bearer ${authCtx.token}`,
      },
    }
  );
};

export const getServerDrivenCarousel = async (offset, authCtx) => {
  let url = authCtx.token
    ? `${process.env.REACT_APP_EIGHT_EVENTS_1}/api/users/fetch/carousel?offset=${offset}`
    : `${process.env.REACT_APP_EIGHT_EXPOSED_API}/api/guest/fetch/carousel?offset=${offset}`;
  let headers = {};
  if (authCtx.token) {
    headers.Authorization = `Bearer ${authCtx.token}`;
  }
  const { data } = await axios.get(url, { headers });
  return data;
  // return { data, currentPage: offset, NextPage: offer + 3 };
};

export const getHeroCarousel = async () => {};

export const getViewAllByCaroselId = async (id, offset, limit, authCtx) => {
  let apiUrl = authCtx?.token
    ? `${process.env.REACT_APP_EIGHT_EVENTS_1}/api/fetch/pc/curation/${id}?offset=${offset}&limit=${limit}`
    : `${process.env.REACT_APP_EIGHT_EXPOSED_API}/api/fetch/pc/curation/${id}?offset=${offset}&limit=${limit}`;

  const headers = authCtx?.token
    ? { Authorization: `Bearer ${authCtx.token}` }
    : {};

  const { data } = await axios.get(apiUrl, { headers });
  return data;
};

export const getMoreLikeThis = async (secondaryGenre, id, authCtx) => {
  let apiUrl = authCtx?.token
    ? `${process.env.REACT_APP_EIGHT_PUBLISH_CONTENT_API}/api/publish/recommend/similar?`
    : `${process.env.REACT_APP_EIGHT_EXPOSED_API}/api/publish/recommend/similar?`;

  const headers = authCtx?.token
    ? { Authorization: `Bearer ${authCtx.token}` }
    : {};

  if (secondaryGenre) {
    apiUrl += `&secondaryGenre=${encodeURIComponent(secondaryGenre)}`;
  }

  if (id) {
    apiUrl += `&id=${id}`;
  }

  const { data } = await axios.get(apiUrl, { headers });

  return data;
};

export const getStreamingHeaders = async (audio, authCtx) => {
  const { data } = await axios.post(
    "https://65bij2y2pl.execute-api.ap-south-1.amazonaws.com/test/api/audiolib/stream/data",
    {
      playback_url: audio,
    },
    {
      headers: {
        Authorization: `Bearer ${authCtx.token}`,
      },
    }
  );

  return data;
};
