import React, { useContext, useState } from "react";
import { Button, Modal, Box, IconButton } from "@mui/material";
import { ReactComponent as GoogleIcon } from "../../../assets/Images/google-icon.svg";
import { ReactComponent as WarningIcon } from "../../../assets/Images/warning-icon.svg";
import { Link } from "react-router-dom";
import AuthCode from "react-auth-code-input";
import CloseIcon from "@mui/icons-material/Close";

import axios from "axios";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  // FacebookAuthProvider,
  signInWithCustomToken,
} from "firebase/auth";
import AuthContext from "../../../Context/AuthContext";
import { auth } from "../../../config/firebaseConfig";
import { create } from "zustand";
import {
  AUTHENTICATION_EVENT,
  REGISTRATION,
  SEND_OTP_EVENT,
} from "../../../Events/events";
// import EightLoader from "../../Backdrop/EightLoader";
import secureLocalStorage from "react-secure-storage";
import OtpTimer from "./OtpTimer";
// import { SEND_OTP_EVENT } from "../../Events/events";

const useStore = create((set) => ({
  open: false,
  message: "",
}));

export const loginModal = (open) => {
  useStore.setState({ open });
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  maxWidth: "30rem",
  minHeight: "30rem",
  boxShadow: 24,
  p: 2,
  borderRadius: "0.5rem",
  background: "#212121",
  outline: "none",
  color: "white",
  display: "grid",
};

const EightApi1 = process.env.REACT_APP_EIGHT_API_1;
const LoginModal = () => {
  const { open } = useStore();
  const authCtx = useContext(AuthContext);
  const [number, setNumber] = useState();
  const [otp, setOtp] = useState();
  const [isOTPsend, setisOTPsend] = useState(false);
  const [name, setName] = useState("");
  const [isRegistration, setisRegistration] = useState({ value: false });
  const [error, seterror] = useState({ phone: "", otp: "" });
  const [otpTimer, setotpTimer] = useState(0);
  const [loading, setLoading] = useState(false);

  const googleLoginHandler = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential.accessToken;
        const user = result.user;
        const userObj = {
          firebaseToken: user.accessToken,
          email: user.email,
          name: user.displayName,
          id: user.uid,
          avatar: user.photoURL || "",
        };
        verifyEmail(userObj);
        AUTHENTICATION_EVENT("google", userObj);
      })
      .catch((error) => {
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // const email = error.customData.email;
        // const credential = GoogleAuthProvider.credentialFromError(error);
      });
  };

  // const facebookLoginHandler = () => {
  //   const auth = getAuth();
  //   const provider = new FacebookAuthProvider();
  //   signInWithPopup(auth, provider)
  //     .then((result) => {
  //       const credential = FacebookAuthProvider.credentialFromResult(result);
  //       // const token = credential.accessToken;
  //       const user = result.user;
  //       const userObj = {
  //         firebaseToken: user.accessToken,
  //         email: user.email,
  //         name: user.displayName,
  //         id: user.uid,
  //         avatar: user.photoURL || "",
  //       };
  //       verifyEmail(userObj);
  //       AUTHENTICATION_EVENT("facebook", userObj);
  //     })
  //     .catch((error) => {
  //       const errorCode = error.code;
  //       const errorMessage = error.message;
  //       const email = error.customData.email;
  //       const credential = GoogleAuthProvider.credentialFromError(error);
  //     });
  // };

  const verifyEmail = async (userObj) => {
    try {
      const { data } = await axios.post(`${EightApi1}/api/verify/user/email`, {
        email: userObj.email,
      });
      if (data.isRegistered) {
        authCtx.login(data.user.token, data.user);
        loginModal(false);
      } else {
        try {
          const userData = {
            firstName: userObj?.name?.split(" ")[0],
            lastName: userObj?.name?.split(" ")[1] || "",
            avatar: userObj?.avatar,
            email: userObj?.email,
          };

          const { data } = await axios.post(
            `${process.env.REACT_APP_EIGHT_NETWORK_API}/api/user`,
            { phone: "1", id: userObj.id }
          );

          if (data.isRegistered === false) {
            await axios.patch(
              `${process.env.REACT_APP_EIGHT_NETWORK_API}/api/user/registration`,
              userData,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${data.token}`,
                },
              }
            );

            authCtx.login(data.token, userData);
            REGISTRATION(userData);
            loginModal(false);
          }
        } catch (err) {}
      }
    } catch (err) {}
  };

  const sendOTPHandler = async () => {
    if (number === process.env.REACT_APP_TEST_LOGIN_NUMBER) {
      setisOTPsend(true);
      setotpTimer(60);
      return;
    }
    if (number.trim()?.length !== 10) {
      seterror({ phone: "Enter a valid phone number" });
      return;
    }
    if (otpTimer === 0) {
      setLoading(true);
      try {
        const resp = await axios.post(`${EightApi1}/api/send/otp`, {
          phone: number,
        });
        if (resp.status === 204) {
          setisOTPsend(true);
          setotpTimer(60);
        }
        SEND_OTP_EVENT(authCtx?.user, number);
      } catch (err) {}
      setLoading(false);
    }
  };
  const OTPverifyHandler = async () => {
    setLoading(true);
    try {
      const resp = await axios.post(`${EightApi1}/api/verify/otp`, {
        otp: otp.toString(),
        phone: "+91" + number,
      });

      if (resp.data.isRegistered) {
        authCtx.login(resp.data?.user?.token, resp.data?.user);
        loginModal(false);
        setisOTPsend(false);
        AUTHENTICATION_EVENT("phone number", number);
      } else {
        setisRegistration({ value: true, token: resp.data.token });
      }
      if (resp.status === 204) {
        setisOTPsend(false);
        loginModal(false);
      } else {
        seterror({ opt: "Please enter a valid OTP" });
      }
    } catch (err) {
      seterror({ otp: "Please enter a valid OTP" });
      setOtp();
    }

    setLoading(false);
  };

  const registrationBtnHandler = async () => {
    setLoading(true);
    if (name.trim()?.length <= 1) {
      seterror({ name: "Please enter you name" });
      return;
    }

    const auth = getAuth();
    signInWithCustomToken(auth, isRegistration.token)
      .then(async (userCredential) => {
        const user = userCredential.user;
        const MobileRegistrationData = {
          id: user.uid,
          phone: "+91" + number,
        };
        const resp = await axios.post(
          `${process.env.REACT_APP_EIGHT_NETWORK_API}/api/user`,
          MobileRegistrationData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${isRegistration.token}`,
            },
          }
        );
        const guestId = secureLocalStorage.getItem("guestId");
        const userData = {
          firstName: name,
          guestId,
        };
        if (resp.data.isRegistered === false) {
          await axios.patch(
            `${process.env.REACT_APP_EIGHT_NETWORK_API}/api/user/registration`,
            userData,
            {
              headers: {
                Authorization: `Bearer ${resp.data.token}`,
              },
            }
          );
          authCtx.login(resp.data.token, userData);
          REGISTRATION(userData);
          setisRegistration();
          loginModal(false);
          return;
        }
        authCtx.login(resp.data.token, userData);
        setisRegistration();
        loginModal(false);
        loginModal(false);
        setLoading(false);
      })
      .catch((error) => {
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // ...
      });
  };

  // const LoadingComponent = () => {
  //   return <Waveform size={40} lineWeight={3.5} speed={1} color="white" />;
  // };

  return (
    <Modal
      open={open}
      onClose={() => loginModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ zIndex: 1000000 }}
    >
      <Box sx={style}>
        <IconButton
          onClick={() => loginModal(false)}
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
        <div className="login-modal">
          {isRegistration?.value ? (
            <div className="registration-content">
              <h5>What’s your first name?</h5>
              <div className="phone-number">
                <input
                  type="text"
                  placeholder="Enter your name here..."
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              {error?.name && error?.name?.trim()?.length !== 0 && (
                <p
                  style={{
                    marginTop: "5px",
                    color: "red",
                    fontFamily: "Poppins",
                  }}
                >
                  {error.name}
                </p>
              )}
              <div className="btn">
                <Button
                  onClick={registrationBtnHandler}
                  id="gta-registrationMobile"
                  disabled={loading || name?.trim()?.length < 2}
                >
                  Submit
                </Button>
              </div>
            </div>
          ) : !isOTPsend ? (
            <div className="content">
              <h3 style={{ textAlign: "center" }}>
                Enjoy Best Audio Stories, Podcasts & Live Shows.
                <p style={{ fontSize: "1.2rem", fontWeight: "400" }}>
                  {" "}
                  Sign Up for free now.{" "}
                </p>
              </h3>
              <div
                className={error?.phone ? "phone-number error" : "phone-number"}
              >
                <span className="country-code">+91 |</span>
                <input
                  value={number}
                  placeholder="Enter Your Phone Number"
                  onChange={(e) => {
                    seterror();
                    setNumber(e.target.value);
                  }}
                  pattern="[6-9]{1}[0-9]{9}"
                />
              </div>
              {error?.phone && error?.phone?.trim()?.length !== 0 && (
                <p
                  style={{
                    marginTop: "5px",
                    color: "red",
                    fontFamily: "Poppins",
                  }}
                >
                  {error.phone}
                </p>
              )}
              <div className="btn">
                <Button
                  onClick={sendOTPHandler}
                  id="gta-sendOtp"
                  disabled={loading || String(number)?.length < 10}
                >
                  Send OTP
                </Button>
              </div>
              {/* <div className="or">
                <div className="leftline"></div>
                <span>or</span>
                <div className="rightline"></div>
              </div> */}
              {/* <div className="btn facebook-btn">
                <Button onClick={facebookLoginHandler} id='gta-facebookLogin'>
                  <FaceBookIcon />
                  Continue with facebook
                </Button>
              </div> */}
              <div className="btn google-btn">
                <Button onClick={googleLoginHandler} id="gta-googleLogin">
                  <GoogleIcon />
                  Continue with google
                </Button>
              </div>
            </div>
          ) : (
            <div className="Otp-modal">
              <h3>Lets get You Verified</h3>
              <p>
                Enter the 6 digit OTP we just sent you on +91 {number} via SMS.{" "}
                <span
                  onClick={() => {
                    setotpTimer(0);
                    setisOTPsend(false);
                  }}
                >
                  edit
                </span>
              </p>
              <div className="phone-number">
                <AuthCode
                  allowedCharacters="numeric"
                  length={6}
                  containerClassName={
                    error?.otp?.trim()?.length !== 0
                      ? "eight-otp-wrp error"
                      : "eight-otp-wrp"
                  }
                  onChange={(val) => {
                    seterror();
                    setOtp(+val);
                  }}
                />
              </div>
              {error?.otp && error?.otp?.trim()?.length !== 0 && (
                <p className="otp-error">
                  <WarningIcon /> {error.otp}
                </p>
              )}
              <OtpTimer number={number} />
              <div className="btn">
                <Button
                  onClick={OTPverifyHandler}
                  disabled={
                    loading ||
                    otp?.toString()?.length < 6 ||
                    otp === null ||
                    otp === undefined
                  }
                  id="gta-nextButton"
                >
                  Next
                </Button>
              </div>
            </div>
          )}
          <div className="terms-conditions">
            By continuing, I accept the{" "}
            <Link
              to="https://www.eight.network/terms-and-conditions"
              target="blank"
            >
              {" "}
              Terms & Conditions{" "}
            </Link>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default LoginModal;
