import HindiLetters from "../assets/Images/hindi-letters-background.png";
import EnglishLetters from "../assets/Images/english-letters-background.png";

export const primaryGenreList = [
  "Adventure & Action",
  "Arts & Literature",
  "Biographies & Documentaries",
  "Business & Technology",
  "Comedy",
  "Crime & Thriller",
  "Environment & Science",
  "Health & Fitness",
  "History & Mythology",
  "Horror",
  "Kids & Parenting",
  "Learning & Development",
  "Love & Relationship",
  "Military & War",
  "Movies & TV",
  "Music",
  "News",
  "Podcast",
  "Politics & Geography",
  "Psychology",
  "Religion & Spirituality",
  "Sci Fi & Fantasy",
  "Self Help",
  "Society & Culture",
  "Sports & Gaming",
  "Start-ups & Entrepreneurship",
];

export const secondaryGenreList = [
  "Action",
  "Adventure",
  "Arts & Literature",
  "Biographies & Memoirs",
  "Documentary",
  "Business",
  "Technology",
  "Comedy",
  "Crime & Thriller",
  "Environment & Science",
  "Health & Fitness",
  "History & Mythology",
  "Horror",
  "Kids & Parenting",
  "Learning & Development",
  "Love & Relationship",
  "Military & War",
  "Movies & TV",
  "Music",
  "News",
  "Podcast",
  "Politics & Geography",
  "Psychology",
  "Religion & Spirituality",
  "Sci-Fi",
  "Fantasy",
  "Self Help",
  "Society & Culture",
  "Gaming",
  "Sports",
  "Start-ups",
];

export const languageList = [
  {
    localDilect: "हिंदी",
    English: "Hindi",
    background: HindiLetters,
    color: "#FA3B38",
  },
  {
    localDilect: "English",
    English: "English",
    background: EnglishLetters,
    color: "#2948EA",
  },
];

export const categoryList = [
  { name: "Audio Stories", color: "#FA3B38" },
  { name: "Podcast Shows", color: "#D68D00BD" },
];

export const colorsArray = [
  "#D83431",
  "#2948EA",
  "#20CAAA",
  "#015A47",
  "#B8167B",
  "#7056D8",
  "#E1B009",
  "#FF97D6",
  "#495DE5",
  "#19A598",
  "#99BD05",
  "#EF4520",
  "#5738AF",
  "#D68D00BD",
  "#C3727C",
  "#C82C8D",
  "#FA3B38",
  "#7E9C03",
  "#00DEB5",
];

export const getRandomColor = () => {
  const randomIndex = Math.floor(Math.random() * colorsArray.length);
  return colorsArray[randomIndex];
};

export const genreColorsArray = [
  { genre: "Adventure And Action", color: "#D83431" },
  { genre: "Arts And Literature", color: "#233FD0" },
  { genre: "Biographies And Documentaries", color: "#015A47" },
  { genre: "Business And Technology", color: "#7056D8" },
  { genre: "Comedy", color: "#F474C2" },

  { genre: "Crime And Thriller", color: "#495DE5" },
  { genre: "Environment And Science", color: "#09867A" },
  { genre: "Health And Fitness", color: "#7E9C03" },
  { genre: "History And Mythology", color: "#EF4520" },
  { genre: "Horror", color: "#5738AF" },

  { genre: "Kids And Parenting", color: "#D68D00BD" },
  { genre: "Learning And Development", color: "#DF8C96" },
  { genre: "Love And Relationship", color: "#B8167B" },
  { genre: "Military And War", color: "#006B54" },
  { genre: "Movies And TV", color: "#00DEB5" },

  { genre: "Music", color: "#20CAAA" },
  { genre: "News", color: "#D83431" },
  { genre: "Podcast", color: "#09867A" },
  { genre: "Politics And Geography", color: "#00DEB5" },
  { genre: "Psychology", color: "#E1B009" },

  { genre: "Religion And Spirituality", color: "#F474C2" },
  { genre: "Sci Fi And Fantasy", color: "#46279F" },
  { genre: "Self Help", color: "#C3727C" },
  { genre: "Society And Culture", color: "#EF4520" },
  { genre: "Sports And Gaming", color: "#7E9C03" },

  { genre: "Start-ups And Entrepreneurship", color: "#B8167B" },
];

export function getColorByGenre(genreName) {
  let lowerCaseGenre = genreName?.toLowerCase();

  lowerCaseGenre = lowerCaseGenre.replace(/&/i, "and");
  for (const { genre, color } of genreColorsArray) {
    if (lowerCaseGenre === genre?.toLowerCase()) {
      return color;
    }
  }

  return null; // Return null if genre not found
}

export function getColorByLanguage(value) {
  let lowerCaseGenre = value?.toLowerCase();

  lowerCaseGenre = lowerCaseGenre.replace(/&/i, "and");
  for (const { English, color } of languageList) {
    if (lowerCaseGenre === English?.toLowerCase()) {
      return color;
    }
  }

  return null; // Return null if genre not found
}

export function getColorByCategory(value) {
  let lowerCaseGenre = value?.toLowerCase();

  lowerCaseGenre = lowerCaseGenre.replace(/&/i, "and");
  for (const { name, color } of categoryList) {
    if (lowerCaseGenre === name?.toLowerCase()) {
      return color;
    }
  }

  return null; // Return null if genre not found
}
