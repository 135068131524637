import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { ReactComponent as CloseIcon } from "../../assets/Images/close_icon.svg";
import { ReactComponent as NextIcon } from "../../assets/Images/navigate-next-icon.svg";
import { ReactComponent as BackIcon } from "../../assets/Images/navigate_back_arrow-icon.svg";
import { Link } from "react-router-dom";
import { categoryList, languageList, primaryGenreList } from "../../Util/Data";
import { genreBreakFormatter } from "../../Util/FormatData/Formatter";

const ChildDrawer = ({ open, setOpen, handleParentDrawerClose, value }) => {
  return (
    <Drawer
      open={open}
      sx={{
        zIndex: "10000",
        width: "100%",
        backdropFilter: "blur(5px)",
        ".MuiPaper-root": {
          width: "75%",
          background: "#212121",
        },
      }}
    >
      <IconButton
        sx={{ position: "fixed", left: "80%", top: "20px" }}
        onClick={handleParentDrawerClose}
      >
        <CloseIcon />
      </IconButton>

      <Box
        sx={{
          paddingTop: "10px",
        }}
      >
        <Box
          sx={{
            color: "var(--heading, #E0E0E0)",
            fontFamily: "Roboto",
            fontSize: "1.8rem",
            fontStyle: "normal",
            fontWeight: 600,
            display: "flex",
            alignItems: "center",
            position: "sticky",
            top: "0px",
            height: "70px",
            width: "100%",
            backgroundColor: "#212121",
            zIndex: "10000",
          }}
        >
          <IconButton onClick={() => setOpen(false)}>
            <BackIcon />
          </IconButton>
          <h3>{value}</h3>
        </Box>
        <List
          sx={{
            paddingLeft: "20px",
          }}
        >
          {(value === "language"
            ? ["Hindi", "English"]
            : value === "genre"
            ? primaryGenreList
            : ["Audio Stories", "Podcast Shows"]
          ).map((text, index) => (
            <Link
              to={`${value}/${
                value === "genre"
                  ? genreBreakFormatter(text)
                  : text?.split(" ")?.join("-")
              }`}
              onClick={handleParentDrawerClose}
              key={index}
            >
              <ListItem
                key={text}
                disablePadding
                sx={{ display: "block" }}
                onClick={() => {}}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    // justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    m: "5px 0px",
                  }}
                >
                  <h4
                    style={{
                      color: "var(--heading, #E0E0E0)",
                      fontFamily: "Roboto",
                      fontSize: "1.2rem",
                      fontStyle: "normal",
                      fontWeight: 500,
                    }}
                  >
                    {text}
                  </h4>
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

const MobileDrawer = ({ open, setOpen }) => {
  const [childDrawerOpen, setChildDrawerOpen] = useState(false);
  const [ChildDrawerValue, setChildDrawerValue] = useState();
  const handleParentDrawerClose = () => {
    setOpen(false);
    setChildDrawerValue();
    setChildDrawerOpen(false);
  };
  return (
    <>
      <Drawer
        open={open}
        sx={{
          zIndex: "10000",
          width: "100%",
          //   backdropFilter: "blur(5px)",
          ".MuiPaper-root": {
            width: "75%",
            background: "#212121",
            height: "100%",
          },
        }}
      >
        <IconButton
          sx={{ position: "fixed", left: "80%", top: "20px" }}
          onClick={handleParentDrawerClose}
        >
          <CloseIcon />
        </IconButton>

        <Box
          sx={{
            color: "white",
            paddingTop: "30px",
            paddingLeft: "20px",
          }}
        >
          <List>
            {["language", "genre", "category"].map((text, index) => (
              <ListItem
                key={index}
                disablePadding
                sx={{ display: "block" }}
                onClick={() => {
                  setChildDrawerValue(text);
                  setChildDrawerOpen(true);
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    // justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    m: "5px 0px",
                  }}
                >
                  <h4
                    style={{
                      color: "var(--heading, #E0E0E0)",
                      fontFamily: "Roboto",
                      fontSize: "1.2rem",
                      fontStyle: "normal",
                      fontWeight: 500,
                      textTransform: "capitalize",
                    }}
                  >
                    {text}
                  </h4>
                  <span>
                    <NextIcon />
                  </span>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
      <ChildDrawer
        open={childDrawerOpen}
        setOpen={setChildDrawerOpen}
        value={ChildDrawerValue}
        handleParentDrawerClose={handleParentDrawerClose}
      />
    </>
  );
};

export default MobileDrawer;
