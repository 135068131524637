import React from "react";

import { ReactComponent as InstagramIcon } from "../../assets/Images/footer-instagram-icon.svg";
import { ReactComponent as FacebookIcon } from "../../assets/Images/footer-facebook-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../assets/Images/footer-youtube-icon.svg";
import { ReactComponent as Linkedin } from "../../assets/Images/footer-linkedin-icon.svg";
import QrCode from "../../assets/Images/Eight-qr-code.png";

import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import InternalLinking from "./InternalLinking";
import Image from "../../Ui/Image";

const Footer = () => {
  return (
    <div className="footer-wrapper">
      <InternalLinking />
      <div className="main-footer">
        <div className="content">
          <div className="heading">
            <h3>Podcasts. Stories. Live.</h3>
          </div>

          <div className="nav-head">
            <h3>
              Podcast. <br />
              Stories. Live.
            </h3>
            <div className="wrapper">
              <h4 style={{ display: "block", marginBottom: "20px" }}>
                Helpful Lnks
              </h4>
              <div className="link-wapper">
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://www.eight.network/"
                >
                  Home
                </Link>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://www.eight.network/"
                >
                  About
                </Link>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://www.eight.network/careers"
                >
                  Careers
                </Link>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://www.eight.network/"
                >
                  Our Team
                </Link>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://www.eight.network/contact"
                >
                  Contact us
                </Link>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://www.eight.network/subscription-policy"
                >
                  Subscription Policy
                </Link>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://www.eight.network/"
                >
                  Blogs
                </Link>
              </div>
            </div>
            <div className="wrapper">
              <h4 style={{ display: "block", marginBottom: "20px" }}>
                Creator Tool
              </h4>

              <div className="link-wapper">
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://studio.eight.network/"
                >
                  Eight studio
                </Link>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://www.eight.network/published-content-creator-central"
                >
                  For Creators
                </Link>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://www.eight.network/musicians-creator-central"
                >
                  For Musicians
                </Link>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://www.eight.network/musicians-creator-central"
                >
                  For Podcasters
                </Link>
              </div>
            </div>

            <div className="wrapper">
              <h4 style={{ display: "block" }}>Follow us on</h4>
              <div className="social-icons">
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://www.instagram.com/eight_network/"
                >
                  <IconButton className="gta-instagram">
                    <InstagramIcon />
                  </IconButton>
                </Link>

                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://www.linkedin.com/company/eightnetwork/"
                >
                  <IconButton className="gta-linkedin">
                    <Linkedin />
                  </IconButton>
                </Link>

                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://www.youtube.com/@eight_network"
                >
                  <IconButton className="gta-youTube">
                    <YoutubeIcon />
                  </IconButton>
                </Link>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://www.facebook.com/eightnetwork"
                >
                  <IconButton className="gta-faceBook">
                    <FacebookIcon />
                  </IconButton>
                </Link>
              </div>
            </div>

            <div className="wrapper desktop">
              <h4 style={{ display: "block" }}>
                Install our Eight App <br></br> for Best Experience
              </h4>
              <div className="social-icons">
                <div>
                  {/* <p>
                    Open camera or any QR scanner<br></br> app on your mobile
                  </p> */}

                  <p>
                    Scan the QR code to <br></br> download the App
                  </p>

                  <div className="play-store">
                    App Available on
                    <span></span>
                  </div>
                </div>
              </div>
            </div>

            <div className="wrapper desktop">
              <Image
                src={QrCode}
                alt="Eight Qr code for play store redirect"
                style={{ width: "180px" }}
              />
            </div>
          </div>

          <div className="child-footer">
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to="https://www.eight.network/community-guidelines"
            >
              Community Guidelines
            </Link>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to="https://www.eight.network/privacy-policy"
            >
              Privacy Policies
            </Link>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to="https://www.eight.network/terms-and-conditions"
            >
              Terms & Conditions
            </Link>
            <p></p>
            <p></p>
            <p></p>
            <p>Made with ❤️ in india</p>
          </div>
        </div>
        {/* <img src={backGroundImg} alt='Eight Photo with peoples listening to audio' loading='lazy' /> */}
      </div>
    </div>
  );
};

export default Footer;
