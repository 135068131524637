import moment from "moment";

export function StreamCountFormatter(num = 0) {
  if (isNaN(num)) {
    throw new Error("Invalid input. Please provide a valid number.");
  }
  const absNum = Math.abs(num);
  if (absNum >= 1e9) {
    return (num / 1e9).toFixed(1) + "B";
  } else if (absNum >= 1e6) {
    return (num / 1e6).toFixed(1) + "M";
  } else if (absNum >= 1e3) {
    return (num / 1e3).toFixed(1) + "K";
  } else {
    return num.toString();
  }
}

export const DateFormatter = (params) => {
  return moment(params).format("DD.MM.YYYY");
};

export const genreBreakFormatter = (string) => {
  const stringWithAnd = string?.replace(/&/g, "and");
  return stringWithAnd?.toLowerCase().split(/[ _]/).join("-");
};

export const genreJoinFormatter = (string) => {
  const stringWithAmpersand = string.replace(/\band\b/g, "&");
  
  if (stringWithAmpersand === "movies-&-tv") {
    return "Movies & TV";
  }

  if (stringWithAmpersand === "sci-fi-&-fantasy") {
    return "Sci-Fi & Fantasy";
  }

  if (stringWithAmpersand === "start-ups-&-entrepreneurship") {
    return "Start-ups & Entrepreneurship";
  }

  let words = stringWithAmpersand.split("-");
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }

  return words.join(" ");
};

export const contentNameFormtter = (string) => {
  return string
    ?.toLowerCase()
    ?.replace(/[^\w\s]/gi, "")
    ?.split(" ")
    ?.join("-");
};

export const categoryFormatter = (string) => {
  return string === "Storytelling" ||
    string === "Drama" ||
    string === "Audiobooks"
    ? "audio-stories"
    : "podcast-shows";
};
