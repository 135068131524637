import React from "react";
import { Button } from "@mui/material";
import {
  StreamCountFormatter,
  categoryFormatter,
  contentNameFormtter,
  genreBreakFormatter,
} from "../../Util/FormatData/Formatter";
import { Link } from "react-router-dom";
import GlassMorphiosmImage from "../../assets/Images/CarouselGlassmorphismImage.png";
import { usePlayerContext } from "../../Context/PlayerContext";
import Image from "../../Ui/Image";
import { getPublishContentNextEpisode } from "../../apis/publishContent";
import { useAuthCtx } from "../../Context/AuthContext";
import { getRssNextEpisode } from "../../apis/rss";
const PrimaryCarouselCard = ({ data }) => {
  const authCtx = useAuthCtx();
  const {
    seriesData,
    playPauseHandler,
    setCarouselName,
    isPlaying,
    setSong,
    setEpisodeList,
  } = usePlayerContext();

  const slidePlayHandler = async (parentData) => {
    if (seriesData?.id !== undefined && parentData.id === seriesData?.id) {
      playPauseHandler();
      return;
    }
    try {
      let data;

      if (parentData.contentType === "RSS") {
        data = await getRssNextEpisode(parentData.id, authCtx);
      } else {
        data = await getPublishContentNextEpisode(parentData.id, authCtx);
      }
      if (!authCtx.token) {
        setSong(data.Items[0], parentData);
      } else {
        setSong(data[0], parentData);
      }
      setEpisodeList(data);

      setCarouselName("HERO");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="primary-carousel-card">
      <div className="content">
        <Link
          to={`/${categoryFormatter(data?.data?.style)}/${genreBreakFormatter(
            data.data.primaryGenre
          )}/${contentNameFormtter(data.data.name)}/${data.data.id}`}
          state={{ carouselName: "Hero Banner" }}
          className="overlay"
        />
        <div className="banner-img">
          <Image
            src={data?.data?.bannerSquare?.md || data?.data?.bannerSquare?.sm}
            alt={data?.data?.banner1Alt || data?.data?.about}
            loading="lazy"
            className="main-img"
            id="img"
            type="rectangle"
          />
        </div>
        <div className="description">
          <h1 className="title">{data?.data.name}</h1>
          <div>
            <div className="tags">
              {data?.data.genre &&
                data.data.genre?.length !== 0 &&
                data.data.genre.slice(0, 3).map((tag, index) => (
                  <div className="tag" key={index}>
                    {tag}
                  </div>
                ))}
            </div>

            {data?.data && (
              <div className="plays-subscribers">
                {StreamCountFormatter(data?.data.streams)} Plays |{" "}
                {StreamCountFormatter(data?.data.subscribers)} Subscribers
              </div>
            )}
          </div>

          <Button
            onClick={() => slidePlayHandler(data.data)}
            sx={{ maxWidth: "300px" }}
          >
            {seriesData?.id === data.data.id && isPlaying ? "Pause" : "Play"}
          </Button>
        </div>
        <img
          src={GlassMorphiosmImage}
          alt="glassmorphism  for audio background"
          className="glassmorphism-img"
        />
      </div>
    </div>
  );
};

export default PrimaryCarouselCard;
