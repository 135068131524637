import React from "react";

const ProgressSlider = ({ progress, sx }) => {
  return (
    <div className="progress-slider" style={sx}>
      <div className="progress" style={{ width: progress + "%" }} />
    </div>
  );
};

export default ProgressSlider;
