import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { EffectCoverflow } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const PrimaryCarouselSkeleton = () => {
  return (
    <SkeletonTheme baseColor="#404040" highlightColor="#606060">
      <div className="Three-d-carousel-container-wrapper">
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          controller={{
            inverse: true,
          }}
          slidesPerView={"auto"}
          // coverflowEffect={{
          //   rotate: 0,
          //   stretch: 0,
          //   depth: 200,
          //   modifier: 3,
          // }}
          modules={[EffectCoverflow]}
          className="Three-d-carousel-container"
        >
          {Array.from({ length: 10 }, (_, i) => (
            <SwiperSlide>
              <div className="primary-carousel-card">
                <div className="content">
                  <div className="banner-img">
                    <Skeleton className="main-img" />
                  </div>
                  <div className="description">
                    <Skeleton className="title" />
                    <div>
                      <div className="tags">
                        {[1, 2, 3].map((tag) => (
                          <Skeleton className="tag" key={tag} />
                        ))}
                      </div>

                      <Skeleton className="plays-subscribers" />
                    </div>

                    <button sx={{ maxWidth: "300px" }}>
                      <Skeleton />
                    </button>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </SkeletonTheme>
  );
};

export default PrimaryCarouselSkeleton;
