import { IconButton } from "@mui/material";
import React, { memo, useContext, useEffect, useState } from "react";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import AuthContext from "../Context/AuthContext";
import { loginModal } from "../components/Modal/Login/LoginModal";

import { PUBLISH_LIKED, PUBLISH_UNLIKED } from "../Events/events";
import axios from "axios";
import { usePlayerContext } from "../Context/PlayerContext";

const LikeButton = memo(({ data, seriesData }) => {
  const authCtx = useContext(AuthContext);

  const { currentSong, setSong } = usePlayerContext();
  const isLiked =
    currentSong.songId === data.songId ? currentSong.isLiked : data.isLiked;

  const [like, setlike] = useState(isLiked);

  useEffect(() => {
    if (!authCtx.token || authCtx.token.trim()?.length === 0) {
      setlike(false);
    }
  }, [authCtx.token]);

  const HandleLike = async (data) => {
    if (authCtx.token === null || authCtx?.token?.length === 0) {
      loginModal(true);
      return;
    }
    setlike((prev) => !prev);

    try {
      const liked = !like ? "like" : "unlike";
      const resp = await axios.post(
        `${process.env.REACT_APP_EIGHT_API_1}/api/audio/library/${liked}`,
        {
          id: data?.songId || data?.id,
        },
        {
          headers: {
            Authorization: `Bearer ${authCtx.token}`,
          },
        }
      );
      const updatedLikes = await resp.data;
      if (liked === "like") {
        PUBLISH_LIKED(data, authCtx?.user);
      } else {
        PUBLISH_UNLIKED(data, authCtx?.user);
      }

      if (data.songId && currentSong?.songId === data?.songId) {
        let newCurrentSong = {
          ...currentSong,
          isLiked: !like,
          likes: updatedLikes.likes,
        };
        setSong(newCurrentSong, seriesData);
      }
    } catch (err) {
      setlike((prev) => !prev);
    }
  };

  if (seriesData?.contentType === "RSS") {
    return <></>;
  }

  return (
    <IconButton
      sx={{
        padding: 0,
        "&.input": {
          position: "absolute",
          visibility: "hidden",
          height: "100%",
          color: "red",
        },
      }}
      onClick={() => HandleLike(data)}
      className="gta-likeButton"
    >
      {currentSong?.songId === data.songId ? (
        currentSong?.isLiked ? (
          <FavoriteIcon sx={{ color: "#CE2A2A", fontSize: "2rem" }} />
        ) : (
          <FavoriteBorderIcon sx={{ color: "#B8B8B8", fontSize: "2rem" }} />
        )
      ) : like ? (
        <FavoriteIcon sx={{ color: "#CE2A2A", fontSize: "2rem" }} />
      ) : (
        <FavoriteBorderIcon sx={{ color: "#B8B8B8", fontSize: "2rem" }} />
      )}
    </IconButton>
  );
});

export default LikeButton;
