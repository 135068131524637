import React, { useContext, useEffect, useReducer, useRef } from "react";
import { SEARCH_ICON_CLICKED } from "../../Events/events";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import { ReactComponent as SearchIcon } from "../../assets/Images/search-icon.svg";
import AuthContext from "../../Context/AuthContext";
import { useState } from "react";
import { debounce } from "../../Util/functions";

const SearchComponent = () => {
  const navigate = useNavigate();
  const [query, setQuery] = useState();
  const authCtx = useContext(AuthContext);
  const inputRef = useRef();

  const [searchParams, setSearchParams] = useSearchParams();
  const isSearchPage = useLocation().pathname === "/search";

  useEffect(() => {
    const debouncedSearch = debounce((query) => {
      setSearchParams({ query: query });
    }, 500);

    if (query && query.length > 2) {
      debouncedSearch(query);
    }

    return () => {
      debouncedSearch.cancel && debouncedSearch.cancel();
    };
  }, [query]);

  useEffect(() => {
    if (!isSearchPage) {
      setQuery();
      setSearchParams();
    }
  }, [isSearchPage]);

  useEffect(() => {
    if (isSearchPage) {
      inputRef.current.focus();
    }
  }, [isSearchPage]);

  return (
    <div className={`search`}>
      <IconButton className="gta-search" sx={{ padding: 0, color: "white" }}>
        <SearchIcon sx={{ width: "1.8rem", height: "1.8rem" }} />
      </IconButton>
      <input
        type="text"
        ref={inputRef}
        value={query}
        autoComplete="off"
        placeholder="Search for shows"
        onFocus={() => {
          SEARCH_ICON_CLICKED("NA", authCtx?.user);
          navigate("/search");
        }}
        id="gta-searchInput"
        onChange={(e) => setQuery(e.target.value)}
      />
    </div>
  );
};

export default SearchComponent;
