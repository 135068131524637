import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as PrevPlayerIcon } from "../assets/Images/10sPrev-Player-icon.svg";
import { ReactComponent as NextPlayerIcon } from "../assets/Images/10sNext-Player-icon.svg";
import { ReactComponent as PlayPlayerIcon } from "../assets/Images/play-player-icon.svg";
import { ReactComponent as PausePlayerIcon } from "../assets/Images/pause-player-icon.svg";
import { ReactComponent as PlaylistIcon } from "../assets/Images/playlist-player-icon.svg";
import { ReactComponent as VolumePlayerIcon } from "../assets/Images/volume-player-icon.svg";
import { ReactComponent as MutedVolumeIcon } from "../assets/Images/volume-muted-icon.svg";
import { ReactComponent as VolumeLess } from "../assets/Images/volume-50-percent-icon.svg";
import { CircularProgress, IconButton, Slider } from "@mui/material";
import LikeButton from "../Ui/LikeButton";
import SpeedChangeHandler from "./MusicPlayerComponents/SpeedChangeHandler";
import { useLocation, useNavigate } from "react-router-dom";
import { usePlayerContext } from "../Context/PlayerContext";

const MusicPlayer = () => {
  const {
    currentSong,
    volumeChangeHandler,
    tenSBackward,
    tenSForward,
    seriesData,
    playPauseHandler,
    audioRef,
    muteHandler,
    isPlaying,
    timelineSlideHandler,
    isLoading,
    updateFormattedDuration,
    updateFormattedCurrentTime,
    progress,
    volume,
  } = usePlayerContext();

  const navigate = useNavigate();
  const isMobilePlayerDisplaying = useLocation().pathname === "/mobile-player";
  return (
    <div className="music-player-wrapper">
      {!isMobilePlayerDisplaying &&
        currentSong &&
        Object.keys(currentSong).length > 0 && (
          <div className="music-player">
            <div className="laptop-music-player">
              <div className="timeline-slider">
                <Slider
                  value={progress || 0}
                  aria-label="Default"
                  valueLabelDisplay="off"
                  onChange={(_, value) => {
                    timelineSlideHandler(value);
                  }}
                />
              </div>

              <div className="time">
                {updateFormattedCurrentTime()}/{updateFormattedDuration()}
              </div>
              <div className="description">
                <img
                  src={
                    currentSong?.bannerSquare?.lg ||
                    currentSong?.banner ||
                    currentSong?.publishContent?.banner
                  }
                  alt={
                    currentSong?.publishContent?.banner1Alt ||
                    currentSong?.publishContent?.about
                  }
                  loading="lazy"
                />
                <div>
                  <h3>{currentSong?.name}</h3>
                  <p>{seriesData?.name}</p>
                </div>
              </div>
              <div className="control-buttons">
                <IconButton onClick={tenSBackward} className="gta-10sBack">
                  <PrevPlayerIcon />
                </IconButton>
                {isLoading ? (
                  <CircularProgress sx={{ color: "white" }} />
                ) : (
                  <IconButton
                    onClick={() => playPauseHandler()}
                    className="gta-playButton"
                  >
                    {isPlaying ? (
                      <PausePlayerIcon style={{ width: "45px" }} />
                    ) : (
                      <PlayPlayerIcon style={{ width: "45px" }} />
                    )}
                  </IconButton>
                )}
                <IconButton onClick={tenSForward} className="gta-10sForward">
                  <NextPlayerIcon />
                </IconButton>
              </div>
              <div className="right-block">
                <SpeedChangeHandler />
                <IconButton
                  className="icon"
                  onClick={() =>
                    navigate(
                      `/${
                        seriesData?.style === "Storytelling" ||
                        seriesData?.style === "Drama" ||
                        seriesData?.style === "Audiobooks"
                          ? "audio-stories"
                          : "podcast-shows"
                      }/${seriesData.primaryGenre
                        .toLowerCase()
                        .split(" ")
                        .join("-")}/${seriesData.name
                        .toLowerCase()
                        .split(" ")
                        .join("-")}/${seriesData.id}`
                    )
                  }
                >
                  <PlaylistIcon />
                </IconButton>
                <LikeButton data={currentSong} seriesData={seriesData} />

                <div className="volume-bar">
                  <IconButton
                    className="icon gta-volumeButton"
                    onClick={muteHandler}
                  >
                    {volume === 0 ? (
                      <MutedVolumeIcon />
                    ) : volume < 50 ? (
                      <VolumeLess />
                    ) : (
                      <VolumePlayerIcon />
                    )}
                  </IconButton>
                  <Slider
                    value={volume || audioRef.current.volume}
                    aria-label="Default"
                    valueLabelDisplay="off"
                    onChange={volumeChangeHandler}
                  />
                </div>
              </div>
            </div>

            <div className="mobile-music-player">
              <div className="timeline-slider">
                <Slider
                  value={progress || 0}
                  aria-label="Default"
                  valueLabelDisplay="off"
                  onChange={(_, value) => {
                    timelineSlideHandler(value);
                  }}
                />
              </div>
              <div
                className="description"
                onClick={(e) => navigate("/mobile-player")}
              >
                <img
                  src={currentSong?.banner || ""}
                  alt={currentSong?.banner1Alt || currentSong?.about}
                  loading="lazy"
                />
                <div>
                  <h3 style={{ fontSize: "1rem" }}>{currentSong?.name}</h3>
                  <div className="time" style={{ fontSize: "0.9rem" }}>
                    {updateFormattedCurrentTime()}/{updateFormattedDuration()}
                  </div>
                </div>
              </div>
              <div className="right-block">
                <LikeButton data={currentSong} seriesData={seriesData} />
                {isLoading ? (
                  <CircularProgress sx={{ color: "white" }} />
                ) : (
                  <IconButton
                    size="medium"
                    onClick={() => playPauseHandler()}
                    sx={{ width: "40px" }}
                    className="gta-playButton"
                  >
                    {isPlaying ? <PausePlayerIcon /> : <PlayPlayerIcon />}
                  </IconButton>
                )}
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

export default MusicPlayer;
