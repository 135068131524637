import { Button } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import AuthContext from "../Context/AuthContext";
import { SUBSCRIBE } from "../Events/events";
import { loginModal } from "../components/Modal/Login/LoginModal";
import { ReactComponent as PlusIcon } from "../assets/Images/plus.svg";
import CheckIcon from '@mui/icons-material/Check';
// data is seriesData
const SubscribeButton = ({ data, childrenType }) => {
  const authCtx = useContext(AuthContext);
  const [subscribe, setsubscribe] = useState(false);

  useEffect(() => {
    const checkSubscibe = async () => {
      try {
        const resp = await axios.get(
          `${process.env.REACT_APP_EIGHT_API_1}/api/publish/record/is/subscribed/${data.id}`,
          {
            headers: {
              Authorization: `Bearer ${authCtx.token}`,
            },
          }
        );
        setsubscribe(resp.data.isSubscribed);
      } catch (err) {
        setsubscribe(false);
      }
    };

    if (authCtx.token?.length > 0) {
      checkSubscibe();
    }
  }, [authCtx.token, data.id]);

  const SubscribeChangeHandler = async () => {
    if (authCtx.token === null || authCtx?.token?.length === 0) {
      loginModal(true);
      return;
    }

    try {
      await axios.post(
        `${
          process.env.REACT_APP_EIGHT_PUBLISH_CONTENT_API
        }/api/publish/record/${!subscribe ? "subscribe" : "unsubscribe"}`,
        {
          id: data.id,
        },
        {
          headers: {
            Authorization: `Bearer ${authCtx.token.trim()}`,
          },
        }
      );

      setsubscribe((prev) => !prev);
      SUBSCRIBE(data, authCtx?.user, !subscribe);
    } catch (err) {}
  };

  let children = subscribe ? "Subscribed" : "Subscribe";

  if (childrenType === "ICON") {
    children = subscribe ? <CheckIcon /> : <PlusIcon />;
  }

  return (
    <Button
      className="subscribe-btn"
      style={
        subscribe
          ? {
              borderRadius: "0.1875rem",
              backgroundColor: "#383838",
              color: "rgba(224, 224, 224, 0.50)",
            }
          : { borderRadius: "0.1875rem", backgroundColor: "#383838" }
      }
      onClick={SubscribeChangeHandler}
    >
      {children}
    </Button>
  );
};

export default SubscribeButton;
