import { useModalStore } from "../../hooks/useModal";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Image from "../../Ui/Image";
import PlayStoreImg from "../../assets/Images/google-play-store-get-the-app.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  maxWidth: "27rem",
  minHeight: "25rem",
  boxShadow: 24,
  p: 2,
  borderRadius: "0.5rem",
  background: "#212121",
  outline: "none",
  color: "white",
  display: "grid",
};

const InfoModal = () => {
  const { isOpen, onClose, type } = useModalStore();
  const isModalOpen = isOpen && type === "INFO_MODA";

  return (
    <Modal
      open={isModalOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ zIndex: 1000000 }}
    >
      <Box sx={style}>
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
        <div className="modal">
          <Typography
            sx={{ fontFamily: "Poppins", textAlign: "center", py: 2 }}
          >
            Unlock the full experience! Subscribe now to gain access to the
            exclusive Premium Show. Don't miss out on the enriching content
            waiting just for you. Join today for a premium listening journey
            unlike any other!
          </Typography>
          <Typography
            sx={{ fontFamily: "Poppins", textAlign: "center", py: 2 }}
          >
            Please download the app to get the subscription
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <a
              href={
                window.result_url
                  ? window.result_url
                  : "https://play.google.com/store/apps/details?id=com.network.eight.android&hl=en&gl=US"
              }
            >
              <Image
                src={PlayStoreImg}
                alt="Play Store Eight App Download"
                style={{ width: "100px" }}
              />
            </a>
          </Box>
        </div>
      </Box>
    </Modal>
  );
};

export default InfoModal;
