import React, { useContext, useEffect, useRef, useState } from "react";
import Avatar from "@mui/material/Avatar";
import { ReactComponent as DownArrowIcon } from "../../assets/Images/down-arrow-icon.svg";
import { ReactComponent as LogoutIcon } from "../../assets/Images/logout-icon.svg";
import { ReactComponent as HamburgerMenuIcon } from "../../assets/Images/hamburger_menu_icon.svg";

import { Box, Button, Divider, IconButton, Modal } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { loginModal } from "../Modal/Login/LoginModal";
import AuthContext from "../../Context/AuthContext";
import { identyDevice } from "../../Events/events";
import PlayStoreImg from "../../assets/Images/google-play-store-get-the-app.png";
import EightLogo from "../../assets/Images/EIGHT-logo-white.png";
import CloseIcon from "@mui/icons-material/Close";
import NavbarMenu from "./NavbarMenu";
import MobileDrawer from "./MobileDrawer";
import SearchComponent from "./SearchComponent";
import Image from "../../Ui/Image";
import BackButton from "../../pages/ShowDetails/ParentListing/BackButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30rem",
  height: "25rem",
  boxShadow: 24,
  p: 2,
  borderRadius: "0.5rem",
  background: "#212121",
  outline: "none",
  color: "white",
  zIndex: 1000000000,
  padding: "1rem 4rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const ModalChildComponent = ({ setmodalOpen }) => {
  const [downloadLink, setDownloadLink] = useState();
  const AnchorDownloadRef = useRef();
  useEffect(() => {
    if (window.result_url) {
      setDownloadLink(window.result_url);
    }
    if (AnchorDownloadRef.current) {
      AnchorDownloadRef.current.href = window.result_url;

      window?.AF_SMART_SCRIPT?.displayQrCode("EightQrCode", {
        logo: EightLogo,
        codeColor: "#000000",
      });
    }
  }, []);

  return (
    <Box sx={{ ...style }}>
      <IconButton
        onClick={() => setmodalOpen(false)}
        sx={{
          position: "absolute",
          top: "10px",
          right: "10px",
          color: "white",
        }}
      >
        <CloseIcon />
      </IconButton>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "18rem",
            height: "22rem",
            borderRadius: "1.0625rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "1rem",
            p: {
              color: "#E0E0E0",
              fontFamily: "Poppins",
              fontSize: "1rem",
              fontWeight: 500,
              textAlign: "center",
            },
          }}
        >
          <Box
            sx={{
              width: "80%",
              height: "70%",
              border: "1px solid white",
              borderRadius: "10px",
              padding: "3px",
              boxShadow: "0px 0px 15px rgba(0,0,0,0.8)",
            }}
            id="EightQrCode"
          ></Box>
          <p>Scan to download</p>
          <a id="AnchorDownloadRef" ref={AnchorDownloadRef} href={downloadLink}>
            <Image
              src={PlayStoreImg}
              alt="Play Store Eight App Download"
              style={{ width: "150px" }}
            />
          </a>
        </Box>
      </Box>
      {/* </Box> */}
    </Box>
  );
};

const Navbar = () => {
  const [profileOpen, setProfileOpen] = useState(false);
  const [modalOpen, setmodalOpen] = useState(false);
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const profileRef = useRef();
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);

  const logoClickHandler = () => {
    if (window.location.pathname === "/") {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
      return;
    }
    navigate("/");
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        setProfileOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [profileOpen]);

  const isSearchPage = window.location.pathname === "/search";

  return (
    <>
      <div
        className={`navbar ${isSearchPage ? "search-open" : ""}`}
        id="navbar"
      >
        <div className="content">
          <Box
            sx={{
              ml: "5px",
              display: "flex",
              gap: "0rem",
              alignItems: "center",
            }}
            className='menu-wrapper'
          >
            <div
              className="menuIcon"
              onClick={() => setMobileDrawerOpen(true)}
              style={{ cursor: "pointer" }}
            >
              <HamburgerMenuIcon />
            </div>
            <Button className="logo gta-home" onClick={logoClickHandler}>
              EIGHT
            </Button>
          </Box>
          <NavbarMenu />
          <div className="back-btn-for-mobile">
            <BackButton />
          </div>

          <SearchComponent />

          <Box
            sx={{ ml: 1, display: "flex", gap: "1rem", alignItems: "center" }}
          >
            {identyDevice() === "web" && (
              <>
                <Button
                  sx={{
                    height: "40px",
                    background: "#383838",
                    color: "#E0E0E0",
                    fontFamily: "Roboto",
                    fontWeight: "500",
                    fontSize: "1rem",
                    padding: "0px 1.2rem",
                    border: "none",
                    textTransform: "capitalize",
                    whiteSpace: "nowrap",
                    "&:hover": { background: "#383842", border: "none" },
                  }}
                  style={
                    authCtx?.token?.length > 1
                      ? { background: "white", color: "black" }
                      : { background: "#383838" }
                  }
                  onClick={() => setmodalOpen(true)}
                  id="gta-openInApp"
                >
                  Download the app
                </Button>
              </>
            )}
            {authCtx.token && authCtx.token.length > 0 ? (
              <>
                <div className="profile-main-wrapper" ref={profileRef}>
                  <div
                    className={`${
                      profileOpen ? "profile-wrapper open" : "profile-wrapper"
                    }`}
                  >
                    <div
                      className="profile"
                      onClick={() => setProfileOpen((prev) => !prev)}
                    >
                      <span>{`${authCtx?.user?.firstName} ${
                        authCtx?.user?.lastName || ""
                      }`}</span>
                      {
                        <Avatar
                          alt="Harshal"
                          src={authCtx?.user?.avatar}
                          sx={{
                            height: "30px",
                            width: "30px",
                            cursor: "pointer",
                          }}
                        />
                      }
                      <div className="arrow-icon">
                        <DownArrowIcon />
                      </div>
                    </div>
                    <Divider />
                    <Link className="profile-menu">
                      {/* <div>
                      <UserProfileIcon />
                      My Profile
                    </div> */}
                      <div
                        onClick={() => {
                          authCtx.logout();
                          setProfileOpen(false);
                        }}
                      >
                        <LogoutIcon />
                        Log out
                      </div>
                    </Link>
                  </div>
                </div>
              </>
            ) : (
              <Button
                onClick={() => loginModal(true)}
                sx={{
                  height: "40px",
                  background: "white",
                  color: "black",
                  fontFamily: "Roboto",
                  fontWeight: "500",
                  fontSize: "1rem",
                  padding: "0px 1.2rem",
                  textTransform: "capitalize",
                  border: "none",
                  whiteSpace: "nowrap",
                  "&:hover": { background: "white", border: "none" },
                }}
              >
                Sign up/Login
              </Button>
            )}
          </Box>
        </div>
      </div>
      {modalOpen && (
        <Modal open={modalOpen} onClose={() => setmodalOpen(false)}>
          <ModalChildComponent setmodalOpen={setmodalOpen} />
        </Modal>
      )}
      <div
        className={`${profileOpen ? "backdrop open" : "backdrop close"}`}
        onClick={() => setProfileOpen(false)}
      ></div>
      <MobileDrawer open={mobileDrawerOpen} setOpen={setMobileDrawerOpen} />
    </>
  );
};

export default Navbar;
