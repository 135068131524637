import { Button } from "@mui/material";
import { ReactComponent as BackArrowImg } from "../../../assets/Images/back-arrow-icon.svg";
import { useNavigate } from "react-router-dom";

const BackButton = () => {

  const navigate = useNavigate();

  return (
    <div className="back-icon">
      <Button
        id="gta-backButtonFromShowListing"
        onClick={() => navigate(-1)}
        sx={{ minWidth: "auto", padding: 0 }}
      >
        <BackArrowImg />
      </Button>
    </div>
  );
};

export default BackButton;
