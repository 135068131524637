import axios from "axios";
import secureLocalStorage from "react-secure-storage";

export const getRssEpisodeList = async (
  id,
  limit,
  authCtx,
  LastEvaluatedKey = null
) => {
  let apiUrl = authCtx.token
    ? `${process.env.REACT_APP_EIGHT_API_1}/api/rss/${id}/feeds?limit=${limit}`
    : `${process.env.REACT_APP_EIGHT_EXPOSED_API}/api/rss/${id}/feeds?limit=${limit}`;

  if (LastEvaluatedKey) {
    apiUrl += `&LastEvaluatedKey=${encodeURIComponent(
      JSON.stringify(LastEvaluatedKey)
    )}`;
  }

  const headers = authCtx.token
    ? { Authorization: `Bearer ${authCtx.token}` }
    : {};

  const { data } = await axios.get(apiUrl, { headers });
  return data;
};

export const getRssEpisodeDetails = async (songId, authCtx) => {
  const guestId = secureLocalStorage.getItem("guestId", null);
  let apiUrl = authCtx.token
    ? `${process.env.REACT_APP_EIGHT_API_1}/api/rss/feed/${songId}`
    : `${process.env.REACT_APP_EIGHT_EXPOSED_API}/api/rss/feed/${songId}?questId=${guestId}`;

  const headers = authCtx.token
    ? { Authorization: `Bearer ${authCtx.token}` }
    : {};

  const { data } = await axios.get(apiUrl, { headers });
  return data;
};

export const getRssNextEpisode = async (seriesId, authCtx) => {
  let apiUrl = authCtx.token
    ? `${process.env.REACT_APP_EIGHT_API_1}/api/rss/next/episode/series/id?seriesId=${seriesId}&limit=2`
    : `${process.env.REACT_APP_EIGHT_EXPOSED_API}/api/rss/${seriesId}/feeds?limit=2`;

  const headers = authCtx?.token
    ? { Authorization: `Bearer ${authCtx.token}` }
    : {};

  const { data } = await axios.get(apiUrl, { headers });
  if (!authCtx.token) {
    return data;
  }
  return data;
};
