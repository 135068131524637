import AWS from 'aws-sdk';




AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
  credentials: new AWS.Credentials({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECREATE_ACCESS_KEY,
  }),
});

export const kinesis = new AWS.Kinesis();
