import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation } from "swiper/modules";
import { ReactComponent as CarouselBackArrow } from "../../assets/Images/carousel-back-arrow.svg";
import { ReactComponent as CarouselNextArrow } from "../../assets/Images/carousel-next-arrow.svg";
import Top10CarouselCard from "../Card/Top10CarouselCard";
import axios from "axios";
import { useAuthCtx } from "../../Context/AuthContext";
import SquareCarouselSkeleton from "../skeleton/carousel/SquareCarouselSkeleton";

const Top10Carousel = ({ carousel }) => {
  const [disableFading, setDisableFading] = useState("isBeginning");
  const [carouselData, setCarouselData] = useState();
  const [loading, setLoading] = useState(true);
  const authCtx = useAuthCtx();
  useEffect(() => {
    const fetchCarouselData = async () => {
      const { data } = await axios.get(carousel.data, {
        headers: { Authorization: `Bearer ${authCtx.token}` },
      });
      setCarouselData(data);

      setTimeout(() => {
        setLoading(false);
      }, [1000]);
    };
    fetchCarouselData();
  }, []);

  if (loading) {
    return <SquareCarouselSkeleton />;
  }
  return (
    <div className="carousel-container-wrapper">
      <div className="heading">
        <span>{carouselData.title}</span>
      </div>
      <Swiper
        grabCursor={true}
        loop={false}
        slidesPerView={"auto"}
        slidesPerGroup={3}
        navigation={{
          nextEl: ".custom-next-button",
          prevEl: ".custom-prev-button",
          clickable: true,
        }}
        modules={[Navigation]}
        allowTouchMove={true}
        className={`top-10-carousel-container carousel-container ${
          disableFading === "isEnd" ? "disabledFading" : ""
        }`}
        onActiveIndexChange={(e) => {
          if (e.isBeginning) {
            setDisableFading("isBeginning");
          } else if (e.isEnd) {
            setDisableFading("isEnd");
          } else {
            setDisableFading(null);
          }
        }}
      >
        {carouselData?.content &&
          carouselData?.content?.length !== 0 &&
          carouselData.content.map((publishCT, index) => (
            <SwiperSlide key={index}>
              <Top10CarouselCard
                key={index}
                publishCT={publishCT}
                index={index}
                carouselName={carouselData.name}
              />
            </SwiperSlide>
          ))}
        <div
          className={`custom-prev-button slider-arrow`}
          style={
            disableFading === "isBeginning"
              ? { display: "none" }
              : { display: "block" }
          }
        >
          <CarouselBackArrow style={{ height: "100%", width: "100%" }} />
        </div>
        <div
          className="custom-next-button slider-arrow"
          style={
            disableFading === "isEnd"
              ? { display: "none" }
              : { display: "block" }
          }
        >
          <CarouselNextArrow style={{ height: "100%", width: "100%" }} />
        </div>
        <div className="swiper-pagination"></div>
      </Swiper>

      {/* <div className="divider"></div> */}
    </div>
  );
};

export default Top10Carousel;
