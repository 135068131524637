import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as PlayIcon } from "../../assets/Images/continue-listening-play-icon.svg";
import { ReactComponent as PauseIcon } from "../../assets/Images/continue-listening-pause-icon.svg";
import { ReactComponent as Xicon } from "../../assets/Images/x-icon.svg";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { IconButton } from "@mui/material";
import AuthContext from "../../Context/AuthContext";
import { Link } from "react-router-dom";
import {
  categoryFormatter,
  contentNameFormtter,
  genreBreakFormatter,
} from "../../Util/FormatData/Formatter";
import { usePlayerContext } from "../../Context/PlayerContext";
import Image from "../../Ui/Image";
import { getRssNextEpisode } from "../../apis/rss";
import { getPublishContentNextEpisode } from "../../apis/publishContent";
import ProgressSlider from "../../Ui/ProgressSlider";

const ContinueListeningCard = ({ publishCT }) => {
  const authCtx = useContext(AuthContext);
  const { seriesData, setEpisodeList, isPlaying, playPauseHandler, setSong } =
    usePlayerContext();

  const [lastPlayed, setLastPlayed] = useState(undefined);

  useEffect(() => {
    const fetchLastPlayedDate = async () => {
      let data;
      if (publishCT.contentType === "RSS") {
        data = await getRssNextEpisode(publishCT.id, authCtx);
      } else if (publishCT.contentType === "PUBLISH_RECORD") {
        data = await getPublishContentNextEpisode(publishCT.id, authCtx);
      }
      setLastPlayed(data);
    };

    fetchLastPlayedDate();
  }, []);

  const continueListeningPlayHanlder = async () => {
    if (seriesData && publishCT.id === seriesData?.id) {
      playPauseHandler();
      return;
    }
    setSong(lastPlayed[0], publishCT);
    setEpisodeList(lastPlayed);
  };

  let lastPlayedProgress = 0;

  if (lastPlayed && lastPlayed[0]) {
    lastPlayedProgress =
      (lastPlayed[0]?.audioProgressData?.listenedDuration /
        lastPlayed[0]?.duration) *
      100;
  }

  const link = `/${categoryFormatter(publishCT?.style)}/${genreBreakFormatter(
    publishCT.primaryGenre
  )}/${contentNameFormtter(publishCT.name)}/${publishCT.id}`;

  return (
    <div className="continue-carousel-card">
      <div className="img-wrapper">
        <Image
          src={publishCT?.bannerSquare?.lg || publishCT?.banner}
          alt={publishCT?.banner1Alt || publishCT?.about}
        />

        <IconButton
          className="icon gta-playButton"
          onClick={continueListeningPlayHanlder}
        >
          {seriesData?.id === publishCT?.id && isPlaying ? (
            <PauseIcon />
          ) : (
            <PlayIcon />
          )}
        </IconButton>
        {publishCT?.contentType === "PUBLISH_RECORD" && (
          <div className="x-icon">
            <Xicon color="red" background="red" />
          </div>
        )}

        <ProgressSlider progress={lastPlayedProgress} />
      </div>
      <Link to={link} state={{ carouselName: "Continue Listening" }}>
        <p>{publishCT?.name}</p>
      </Link>
    </div>
  );
};

export default ContinueListeningCard;
