import { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { CopyToClipboard } from "react-copy-to-clipboard";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as ShareIcon } from "../assets/Images/share-icon.svg";
import InsertLinkIcon from "@mui/icons-material/InsertLink";

import { FacebookShareButton, WhatsappShareButton } from "react-share";
import { FacebookIcon, WhatsappIcon } from "react-share";
import { Box, Modal } from "@mui/material";

const ShareButton = ({ sx, size = "medium" }) => {
  const [open, setOpen] = useState(false);
  const [shareModal, setshareModal] = useState(false);
  const buttonStyle = {
    width: 40,
    height: 40,
    borderRadius: "8px",
    overflow: "hidden",
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#0f0f0f",
    border: "2px solid #2A2A2A",
    boxShadow: 24,
    p: 4,
    display: "flex",
    alignItem: "center",
    justifyContent: "space-around",
  };

  let shareUrl = window.location.href;
  const shareTitle = `Hey! I listen to the series on Eight for free !!!`;
  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };
  const shareModalCloseHandler = () => {
    setshareModal(false);
    handleTooltipClose();
  };

  const shareModalOpenHandler = () => {
    setshareModal(true);
  };

  return (
    <>
      <IconButton
        sx={{ padding: 0, color: "white", width: "25px" }}
        onClick={shareModalOpenHandler}
        className="gta-shareButton"
      >
        <ShareIcon fontSize={size} />
      </IconButton>
      <Modal
        open={shareModal}
        onClose={shareModalCloseHandler}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* <FacebookShareButton
          url={shareUrl}
          quote={shareTitle}
        
          style={{ ...buttonStyle, background: "#3770E3" }}
        >
          <FacebookIcon size={size} />
        </FacebookShareButton> */}
          <WhatsappShareButton
            title={shareTitle}
            url={shareUrl}
            style={{ ...buttonStyle, background: "#3770E3" }}
          >
            <WhatsappIcon size={size} />
          </WhatsappShareButton>
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipClose}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title="Copied to clipboard"
            >
              <span>
                <CopyToClipboard text={shareUrl}>
                  <IconButton
                    onClick={handleTooltipOpen}
                    style={{
                      ...buttonStyle,
                      background: "#FFFFFF",
                      color: "#000",
                    }}
                  >
                    <InsertLinkIcon />
                  </IconButton>
                </CopyToClipboard>
              </span>
            </Tooltip>
          </ClickAwayListener>
        </Box>
      </Modal>
    </>
  );
};

export default ShareButton;
