import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Xicon } from "../../assets/Images/x-icon.svg";
import {
  categoryFormatter,
  contentNameFormtter,
  genreBreakFormatter,
} from "../../Util/FormatData/Formatter";
import Image from "../../Ui/Image";

const SquareCarouselCard = ({ publishCT, carouselName }) => {
  const link = `/${categoryFormatter(publishCT?.style)}/${genreBreakFormatter(
    publishCT?.primaryGenre
  )}/${contentNameFormtter(publishCT?.name)}/${publishCT?.id}`;

  return (
    <Link to={link} state={{ carouselName: carouselName }}>
      <div className="square-carousel-card">
        <Image
          src={publishCT?.bannerSquare?.md || publishCT?.bannerSquare?.sm}
          alt={publishCT?.banner1Alt || publishCT?.about}
        />
        {publishCT?.contentType === "PUBLISH_RECORD" && (
          <div className="x-icon">
            <Xicon color="red" background="red" />
          </div>
        )}
        <p>{publishCT.name}</p>
      </div>
    </Link>
  );
};

export default SquareCarouselCard;
