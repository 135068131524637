import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Xicon } from "../../assets/Images/x-icon.svg";
import {
  categoryFormatter,
  contentNameFormtter,
  genreBreakFormatter,
} from "../../Util/FormatData/Formatter";
import Image from "../../Ui/Image";
import ResumePlayButton from "../../Ui/ResumePlayButton";
import { IconButton } from "@mui/material";
import { ReactComponent as RatingIcon } from "../../assets/Images/rating-icon.svg";

const NewSquareCarouselCard = ({ publishCT, carouselName }) => {
  const link = `/${categoryFormatter(publishCT?.style)}/${genreBreakFormatter(
    publishCT?.primaryGenre
  )}/${contentNameFormtter(publishCT?.name)}/${publishCT?.id}`;

  useEffect(() => {
    document
      .querySelector(".new-square-carousel-card")
      .addEventListener("mouseover", function () {
        document.querySelector(".swiper").style.overflow = "visible";
      });

    document
      .querySelector(".new-square-carousel-card")
      .addEventListener("mouseout", function () {
        document.querySelector(".swiper").style.backgroundColor = "hidden";
      });
  }, []);

  if (!publishCT) {
    return;
  }

  return (
    <div className="new-square-carousel-card-wrapper">
      <div className="new-square-carousel-card">
        <Link
          to={link}
          state={{ carouselName }}
          style={{ width: "100%", height: "100%" }}
        >
          <Image
            src={publishCT?.bannerSquare?.md || publishCT?.bannerSquare?.sm}
            alt={publishCT?.banner1Alt || publishCT?.about}
          />
          {publishCT?.contentType === "PUBLISH_RECORD" && (
            <div className="x-icon">
              <Xicon color="red" background="red" />
            </div>
          )}
          <p className="main-title">{publishCT.name}</p>
          <div className="playButton">
            {publishCT.type && (
              <ResumePlayButton
                parentdata={publishCT}
                carouselName={carouselName}
              />
            )}
            <IconButton>+</IconButton>
          </div>
          <p className="title">{publishCT.name}</p>
          <div className="raing-plays">
            <RatingIcon /> 4.5 | 37.4K Plays
          </div>
          <div className="about">{publishCT.about}</div>
        </Link>
      </div>
    </div>
  );
};

export default NewSquareCarouselCard;
