import React, { useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const Image = ({ src, alt, type, ...props }) => {
  const [loading, setLoading] = useState(true);
  return (
    <SkeletonTheme SkeletonTheme baseColor="#404040" highlightColor="#606060">
      {loading && (
        <Skeleton
          className={
            type === "rectangle"
              ? "primary-carousel-card"
              : "square-carousel-card"
          }
        />
      )}
      <img
        src={src}
        alt={alt}
        // onError={(e) => {
        //   e.target.src = src;
        // }}
        onLoad={() => {
          setLoading(false);
        }}
        style={loading ? { visibility: "hidden" } : {}}
        {...props}
      />
    </SkeletonTheme>
  );
};

export default Image;
