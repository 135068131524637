import axios from "axios";
import secureLocalStorage from "react-secure-storage";

export const getPublishContentChildList = async (
  id,
  limit,
  authCtx,
  LastEvaluatedKey = null
) => {
  let apiUrl = authCtx?.token
    ? `${process.env.REACT_APP_EIGHT_API_1}/api/publish/record/${id}/audio/library/resume?limit=${limit}`
    : `${process.env.REACT_APP_EIGHT_EXPOSED_API}/api/publish/record/${id}/audio/library?limit=${limit}`;

  if (LastEvaluatedKey) {
    apiUrl += `&LastEvaluatedKey=${encodeURIComponent(
      JSON.stringify(LastEvaluatedKey)
    )}`;
  }

  const headers = authCtx?.token
    ? { Authorization: `Bearer ${authCtx.token}` }
    : {};

  const { data } = await axios.get(apiUrl, { headers });
  return data;
};

export const getPublishContentEpisodeDetails = async (songId, authCtx) => {
  const guestId = secureLocalStorage.getItem("guestId", null);
  let apiUrl = authCtx?.token
    ? `${process.env.REACT_APP_EIGHT_API_1}/api/audio/library/${songId}`
    : `${process.env.REACT_APP_EIGHT_EXPOSED_API}/api/audio/library/${songId}?guestId=${guestId}`;

  const headers = authCtx?.token
    ? { Authorization: `Bearer ${authCtx.token}` }
    : {};

  const { data } = await axios.get(apiUrl, { headers });
  return data;
};

export const getPublishContentNextEpisode = async (seriesId, authCtx) => {
  let apiUrl = authCtx.token
    ? `${process.env.REACT_APP_EIGHT_PUBLISH_CONTENT_API}/api/audio/library/next/episode/series/id?seriesId=${seriesId}&limit=5`
    : `${process.env.REACT_APP_EIGHT_EXPOSED_API}/api/publish/record/${seriesId}/audio/library?limit=5`;

  const headers = authCtx.token
    ? { Authorization: `Bearer ${authCtx.token}` }
    : {};

  const { data } = await axios.get(apiUrl, { headers });

  if (!authCtx?.token) {
    return data;
  }
  return data;
};

export const getPublishContentParentList = async () => {};
