import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

import {
  EffectCoverflow,
  Pagination,
  Navigation,
  Autoplay,
} from "swiper/modules";
import PrimaryCarouselCard from "../Card/PrimaryCarouselCard";
import { useEffect, useState } from "react";
import axios from "axios";
import PrimaryCarouselSkeleton from "../skeleton/carousel/PrimaryCarouselSkeleton";

const ThreeDCarousel = () => {
  const [HeroBanner, setHeroBanner] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const FetchHerobanners = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_EIGHT_EXPOSED_API}/api/hero/publish/content`
        );
        setHeroBanner(data);
        setLoading(false);
        window.scrollTo(0, 0);
      } catch (err) {}
    };
    FetchHerobanners();
  }, []);

  if (loading) {
    return <PrimaryCarouselSkeleton />;
  }
  return (
    <div className="Three-d-carousel-container-wrapper">
      {!loading && (
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          autoplay={{ delay: 2000, disableOnInteraction: false }}
          centeredSlides={true}
          loop={true}
          controller={{
            inverse: true,
          }}
          slidesPerView={"auto"}
          breakpoints={{
            300: {
              coverflowEffect: {
                rotate: 0,
                stretch: 0,
                depth: 200,
                modifier: 3,
              },
            },

            800: {
              coverflowEffect: {},
            },
          }}
          pagination={{ el: ".swiper-pagination", clickable: true }}
          modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
          className="Three-d-carousel-container"
        >
          {!loading &&
            HeroBanner &&
            HeroBanner?.length >= 1 &&
            HeroBanner.map((data, index) => (
              <SwiperSlide key={index}>
                <PrimaryCarouselCard data={data} index={index} />
              </SwiperSlide>
            ))}
        </Swiper>
      )}
    </div>
  );
};

export default ThreeDCarousel;
