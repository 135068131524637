import "swiper/css";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as CarouselBackArrow } from "../../assets/Images/carousel-back-arrow.svg";
import { ReactComponent as CarouselNextArrow } from "../../assets/Images/carousel-next-arrow.svg";
import axios from "axios";
import { useAuthCtx } from "../../Context/AuthContext";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import { Navigation } from "swiper/modules";
import GridViewEightCarouselCardMobile from "../Card/GridViewEightCarouselCardMobile.jsx";
import SquareCarouselSkeleton from "../skeleton/carousel/SquareCarouselSkeleton";

const GridViewEightCarouselMobile = ({ carousel }) => {
  const [carouselData, setCarouselData] = useState();
  const authCtx = useAuthCtx();
  const [loading, setLoading] = useState(true);

  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const swiperRef = useRef(null);
  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.on("activeIndexChange", (e) => {
        const Index = e.realIndex;
        setActiveSlideIndex(Index);
      });
    }
  }, [swiperRef.current]);

  useEffect(() => {
    setLoading(true);
    const fetchCarouselData = async () => {
      const { data } = await axios.get(carousel.data, {
        headers: { Authorization: `Bearer ${authCtx.token}` },
      });
      setCarouselData(data);

      setTimeout(() => {
        setLoading(false);
      }, [1000]);
    };
    fetchCarouselData();
  }, []);

  if (loading) {
    <SquareCarouselSkeleton />;
  }
  if (!carouselData) {
    return;
  }

  return (
    <div className="grid-view-8-carousel-mobile">
      <div className="heading">
        <span>{carouselData?.title}</span>
        {carousel?.isViewAll && (
          <Link to={`/show/view-all/${carouselData?.id}`}>View All</Link>
        )}
      </div>
      <Swiper
        grabCursor={true}
        draggable
        loop={false}
        navigation={{
          nextEl: ".custom-next-button",
          prevEl: ".custom-prev-button",
          clickable: true,
        }}
        slidesPerGroup={1}
        slidesPerView={"auto"}
        modules={[Navigation]}
        allowTouchMove={true}
        className={`carousel-container grid-view-8-carousel-container-mobile `}
        onSwiper={(e) => (swiperRef.current = e)}
      >
        {carouselData?.content?.map((data, index) => (
          <SwiperSlide key={index}>
            <GridViewEightCarouselCardMobile
              key={index}
              publishCT={data}
              carouselName={carouselData.name}
            />
          </SwiperSlide>
        ))}

        {/* <div
          className={`custom-prev-button slider-arrow`}
          style={
            !swiperRef?.current?.allowSlidePrev ||
            swiperRef?.current?.isBeginning
              ? { display: "none" }
              : { display: "block" }
          }
        >
          <CarouselBackArrow style={{ height: "100%", width: "100%" }} />
        </div>
        <div
          className="custom-next-button slider-arrow"
          style={
            !swiperRef?.current?.allowSlideNext || swiperRef?.current?.isEnd
              ? { display: "none" }
              : { display: "block" }
          }
        >
          <CarouselNextArrow style={{ height: "100%", width: "100%" }} />
        </div> */}
      </Swiper>
    </div>
  );
};

export default GridViewEightCarouselMobile;
