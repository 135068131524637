import React, { useState } from "react";
import { internalLinks } from "./InternalLinkingData";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowIcon } from "../../assets/Images/arrow_drop_down_red.svg";
import { Button } from "@mui/material";

const ShowsLinks = ({ i }) => {
  const [viewAll, setViewAll] = useState(false);
  return (
    <div className="links-child-wrapper">
      <h4>{i.subTitle}</h4>
      <div className="shows">
        {i.Links.slice(0, viewAll ? i.Links.length : 3).map((link, index) => (
          <Link to={`${window.location.origin}${link.Link}`} key={index}>
            {link.name}
          </Link>
        ))}
      </div>
      <p
        onClick={() => setViewAll((prev) => !prev)}
        className={`${viewAll ? "rotate-arrow" : ""}`}
      >
        View All <ArrowIcon />{" "}
      </p>
    </div>
  );
};

const InternalLinking = () => {
  const [collapse, setCollapse] = useState(true);
  return (
    <>
      <div
        className={`${
          collapse ? "internal-linking collapse" : "internal-linking"
        }`}
      >
        {internalLinks.map((item, index) => (
          <div className="links-parent-wrapper" key={index}>
            <h3>{item.mainTitle}</h3>
            <div className="link-container">
              {item.content.map((i, index) => (
                <ShowsLinks i={i} key={index} />
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className={`${
          collapse ? "collapse-button " : "collapse-button collapse"
        }`}>
        <Button endIcon={<ArrowIcon />} onClick={()=>setCollapse(prev => !prev )}>
          {collapse ? "Expand All" : "Collapse All"}
        </Button>
      </div>
    </>
  );
};

export default InternalLinking;
