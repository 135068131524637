import React from "react";
import { useModalStore } from "../../hooks/useModal";
import { Box, Button, IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Image from "../../Ui/Image";
import { useAuthCtx } from "../../Context/AuthContext";

const PremiumModal = () => {
  const { isOpen, onClose, data, type } = useModalStore();
  const authCtx = useAuthCtx();
  const isModalOpen = isOpen && type === "INFO_MODAL";

  return (
    <Modal
      open={isModalOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ zIndex: 10000 }}
    >
      <Box className="modal-wrapper">
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
        <div className="modal-content">
          <h2 className="user-name ">
            Hello {authCtx?.user?.firstName || "Guest"}!
          </h2>
          <Image
            src={data?.bannerSquare?.md || data.banner}
            alt={data?.name}
            className="content-img"
          />
          <h3 className="msg">
            Unlock Premium Stories for Free with 7-days Free Trial!
          </h3>
          <a
            href={
              window.result_url
                ? window.result_url
                : "https://play.google.com/store/apps/details?id=com.network.eight.android&hl=en&gl=US"
            }
            target="_blank"
            className="btn-wrapper"
          >
            <Button>Get the App on PlayStore</Button>
          </a>
        </div>
      </Box>
    </Modal>
  );
};

export default PremiumModal;
