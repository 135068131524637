import React from "react";
import "./index.scss";
import App from "./App";
import { PlayerProvider } from "./Context/PlayerContext";
import { AuthContextProvider } from "./Context/AuthContext";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { hydrate, render } from "react-dom";
import "react-loading-skeleton/dist/skeleton.css";
import { QueryClient, QueryClientProvider } from "react-query";

const rootElement = document.getElementById("root");
const queryClient = new QueryClient();

if (rootElement.hasChildNodes()) {
  hydrate(
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <AuthContextProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AuthContextProvider>
      </QueryClientProvider>
    </HelmetProvider>,
    rootElement
  );
} else {
  render(
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <AuthContextProvider>
          <PlayerProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </PlayerProvider>
        </AuthContextProvider>
      </QueryClientProvider>
    </HelmetProvider>,
    rootElement
  );
}

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   // <React.StrictMode>

//   // </React.StrictMode>
// );
