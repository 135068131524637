import React, { useEffect } from "react";
import {
  Box,
  CircularProgress,
  IconButton,
  Modal,
  Slider,
} from "@mui/material";
import { ReactComponent as PrevPlayerIcon } from "../../assets/Images/10sPrev-Player-icon.svg";
import { ReactComponent as NextPlayerIcon } from "../../assets/Images/10sNext-Player-icon.svg";
import { ReactComponent as PlayPlayerIcon } from "../../assets/Images/play-player-icon.svg";
import { ReactComponent as PausePlayerIcon } from "../../assets/Images/pause-player-icon.svg";
import { ReactComponent as PlaylistIcon } from "../../assets/Images/playlist-player-icon.svg";

import LikeButton from "../../Ui/LikeButton";
import ShareButton from "../../Ui/ShareButton";
import SpeedChangeHandler from "../MusicPlayerComponents/SpeedChangeHandler";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { StreamCountFormatter } from "../../Util/FormatData/Formatter";
import { usePlayerContext } from "../../Context/PlayerContext";

const MobileMusicPlayerModal = () => {
  const navigate = useNavigate();
  const {
    currentSong,
    timelineSlideHandler,
    seriesData,
    tenSBackward,
    tenSForward,
    playPauseHandler,
    updateFormattedCurrentTime,
    updateFormattedDuration,
    isPlaying,
    isLoading,
    progress,
  } = usePlayerContext();

  const handleClose = () => {
    navigate(-1);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "90%",
        margin: "auto",
        padding: "5px 0px 0px 0px",
        display: "grid",
        alignItems: "center",
        color: "white",
      }}
    >
      <Box
        sx={{
          height: "40px",
          width: "40px",
          background: "#0F0F0F",
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mb: 3,
          ml: 3,
        }}
        onClick={handleClose}
      >
        <KeyboardArrowDownIcon sx={{ color: "white" }} />
      </Box>
      <Box sx={{ width: "80%", margin: "auto" }}>
        <img
          src={currentSong?.bannerSquare?.lg || currentSong?.banner}
          alt={currentSong?.banner1Alt || currentSong?.about}
          style={{ width: "100%" }}
        />
      </Box>
      <Box
        sx={{ width: "80%", margin: "auto", mt: 2.5 }}
        display={"flex"}
        justifyContent={"space-between"}
      >
        <Box sx={{ width: "100%" }}>
          <h3
            style={{
              fontFamily: "Roboto",
              fontSize: "1.31856rem",
              fontWeight: "600",
              width: "90%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {currentSong?.name}
          </h3>
          <p
            style={{
              fontFamily: "Roboto",
              fontSize: "1rem",
              fontWeight: "600",
              opacity: 0.5,
              marginTop: "10px",
            }}
          >
            {seriesData?.name}
          </p>
          <p
            style={{
              fontFamily: "Roboto",
              fontSize: "0.9rem",
              fontStyle: "normal",
              fontWeight: "400",
              opacity: 0.6,
              marginTop: "9px",
            }}
          >
            {StreamCountFormatter(currentSong?.streams)} plays
          </p>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <LikeButton
            sx={{ width: "25px", height: "25px" }}
            data={currentSong}
            seriesData={seriesData}
          />
          <p
            style={{
              fontFamily: "Roboto",
              fontSize: "0.9rem",
              fontStyle: "normal",
              fontWeight: "400",
              opacity: 0.6,
              marginTop: "3px",
            }}
          >
            {StreamCountFormatter(currentSong?.likes || 0)}
          </p>
        </Box>
      </Box>
      <Box sx={{ width: "80%", margin: "auto", mt: 4, alignSelf: "flex-end" }}>
        <Box sx={{ width: "100%" }}>
          <Slider
            value={progress || 0}
            aria-label="Default"
            valueLabelDisplay="off"
            onChange={(_, value) => {
              timelineSlideHandler(value);
            }}
            sx={{
              padding: "10px 0px",
              color: "#CE2A2A",
              borderRadius: "1px",
              ".MuiSlider-rail": {
                background: "#595959",
              },
              ".MuiSlider-thumb": {
                width: "16px",
                height: "16px",
                color: "white",
                border: "3px solid #212121",

                "&:hover": {
                  backgroundColor: "none",
                },
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            fontFamily: "poppins",
            fontSize: "1rem",
            fontStyle: "normal",
            fontWeight: "400",
          }}
        >
          <p>{updateFormattedCurrentTime()}</p>
          <p>{updateFormattedDuration()}</p>
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <IconButton
            onClick={() => {
              navigate(
                `/${
                  seriesData?.style === "Storytelling" ||
                  seriesData?.style === "Drama" ||
                  seriesData?.style === "Audiobooks"
                    ? "audio-stories"
                    : "podcast-shows"
                }/${seriesData.primaryGenre
                  .toLowerCase()
                  .split("_")
                  .join("-")}/${seriesData.name
                  .toLowerCase()
                  .split(" ")
                  .join("-")}/${seriesData.id}`
              );
            }}
          >
            <PlaylistIcon />
          </IconButton>

          <div className="control-buttons">
            <IconButton onClick={tenSBackward} className="gta-10sBack">
              <PrevPlayerIcon />
            </IconButton>
            {
              <IconButton className="gta-playButton">
                {isLoading ? (
                  <CircularProgress sx={{ color: "white" }} />
                ) : isPlaying ? (
                  <PausePlayerIcon onClick={() => playPauseHandler()} />
                ) : (
                  <PlayPlayerIcon onClick={() => playPauseHandler()} />
                )}
              </IconButton>
            }
            <IconButton onClick={tenSForward} className="gta-10sForward">
              <NextPlayerIcon />
            </IconButton>
          </div>
          <ShareButton />
        </Box>
      </Box>

      <Box
        sx={{ width: "90%" }}
        display={"flex"}
        justifyContent={"space-between"}
      >
        <IconButton sx={{ color: "white", width: "40px" }}>
          {/* <AccessAlarmsIcon sx={{ width: "30px", height: "30px", color: '#B8B8B8' }}/> */}
        </IconButton>
        <SpeedChangeHandler />
      </Box>
    </Box>
  );
};

export default MobileMusicPlayerModal;
