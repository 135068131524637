import { Button, CircularProgress } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { ReactComponent as PlaylistPlayIcon } from "../assets/Images/playlist-play-icon.svg";
import { ReactComponent as PlaylistPauseIcon } from "../assets/Images/playlist-pause-icon.svg";
import {
  PUBLISH_CONTENT_CLICKED,
  PUBLISH_CONTENT_ENDED,
  TUNED_IN,
  TUNED_OUT,
} from "../Events/events";
import AuthContext from "../Context/AuthContext";
import { useParams } from "react-router-dom";
import { usePlayerContext } from "../Context/PlayerContext";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { getContentAccess } from "../Util/functions";
import { getPublishContentNextEpisode } from "../apis/publishContent";
import { getRssNextEpisode } from "../apis/rss";

const ResumePlayButton = ({
  parentData,
  carouselName,
  children,
  className,
}) => {
  const [prevPlayed, setPrevPlayed] = useState();
  const [localEpisodeList, setLocalEpisodeList] = useState({});
  const [loading, setLoading] = useState(true);

  const authCtx = useContext(AuthContext);
  const params = useParams();
  const {
    currentSong,
    playPauseHandler,
    seriesData,
    setSong,
    isPlaying,
    setEpisodeList,
  } = usePlayerContext();

  useEffect(() => {
    if (currentSong) {
      setPrevPlayed(currentSong);
    }
  }, [currentSong]);

  const getPrevExclusiveData = async () => {
    try {
      const data = await getPublishContentNextEpisode(params.id, authCtx);
      if (!authCtx.token) {
        setPrevPlayed(data.Items[0]);
      } else {
        setPrevPlayed(data[0]);
      }
      setLocalEpisodeList(data);
    } catch (error) {
      console.log(error);
    }
  };
  const getRssEpisodeData = async () => {
    try {
      const data = await getRssNextEpisode(params.id, authCtx);
      if (!authCtx.token) {
        setPrevPlayed(data.Items[0]);
      } else {
        setPrevPlayed(data[0]);
      }
      setLocalEpisodeList(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setLoading(true);

    if (params.id) {
      if (parentData?.contentType === "PUBLISH_RECORD") {
        getPrevExclusiveData();
      } else {
        getRssEpisodeData();
      }
    }
    setLoading(false);
  }, [params?.id]);

  const playlistPlayHandler = () => {
    setEpisodeList(localEpisodeList);
    if (
      Object.keys(currentSong)?.length !== 0 &&
      seriesData?.id === parentData.id
    ) {
      playPauseHandler();
    } else {
      if (prevPlayed && Object.keys(prevPlayed)?.length > 0) {
        PUBLISH_CONTENT_CLICKED(
          prevPlayed,
          parentData,
          authCtx?.user,
          carouselName
        );
        TUNED_IN(prevPlayed, parentData, authCtx?.user, carouselName);
        setSong(prevPlayed, parentData);
      }
    }

    if (currentSong !== null && currentSong?.songId?.length !== 0) {
      TUNED_OUT(
        currentSong,
        seriesData,
        authCtx?.user,
        "SongChanged",
        carouselName
      );
      PUBLISH_CONTENT_ENDED(
        currentSong,
        seriesData,
        authCtx?.user,
        "SongChanged",
        carouselName
      );
    }
  };

  const contentAccess = getContentAccess(
    parentData?.accessType,
    prevPlayed?.audioProgressData?.isActive,
    prevPlayed?.isGuest,
    authCtx?.user
  );

  let buttonText = "Play";
  buttonText =
    seriesData?.id === parentData?.id && isPlaying
      ? "Pause "
      : prevPlayed && prevPlayed[0]?.serialNumber
      ? "Resume "
      : "play " +
        (parentData?.type !== "PUBLISH_RECORD_SINGLE"
          ? "EP: " + ((prevPlayed && prevPlayed?.serialNumber) || "1")
          : "");

  if (children) {
    buttonText = children;
  }

  if (loading) {
    <Button>
      <CircularProgress sx={{ color: "white" }} />
    </Button>;
  }

  return (
    <Button
      id="playlistButton"
      className={`gta-playButton ${className}`}
      onClick={playlistPlayHandler}
      startIcon={
        contentAccess ? (
          seriesData?.id === parentData?.id && isPlaying ? (
            <PlaylistPauseIcon style={{ width: "20px" }} />
          ) : (
            <PlaylistPlayIcon style={{ width: "20px" }} />
          )
        ) : (
          <LockOutlinedIcon style={{ width: "18px" }} />
        )
      }
    >
      {buttonText}
    </Button>
  );
};

export default ResumePlayButton;
