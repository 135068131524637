import { Button } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";

const OtpTimer = ({ number }) => {
  const [otpTimer, setOtpTimer] = useState(60);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const timeOut = setInterval(() => {
      setOtpTimer((prev) => prev - 1);
    }, 1000);

    // Clear the interval when otpTimer reaches zero
    if (otpTimer === 0) {
      clearInterval(timeOut);
    }
    return () => clearInterval(timeOut);
  }, [otpTimer]);

  const resendOtpHandler = async () => {
    if (otpTimer === 0) {
      setLoading(true);
      try {
        const resp = await axios.post(
          `${process.env.REACT_APP_EIGHT_API_1}/api/send/otp`,
          {
            phone: number,
          }
        );
        if (resp.status === 204) {
          setOtpTimer(60);
        }
      } catch (err) {}
      setLoading(false);
    }
  };

  return (
    <Button
      size="small"
      // className="resend-otp"
      onClick={resendOtpHandler}
      sx={{
        textTransform: "normal",
        fontFamily: "Poppins",
        color: "white",
        my: 2,
        paddingLeft:0,
        ":disabled": {
          color: "grey",
        },
      }}
      disabled={otpTimer > 0 || loading}
    >
      Resend otp {" "}
      { otpTimer > 0 ? "in " + otpTimer : ""}
    </Button>
  );
};

export default OtpTimer;
