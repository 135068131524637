export const getContentAccess = (accessType, isActive, isGuest, user) => {
  if (
    user?.subscriptionPlanInfo?.subscriptionState ===
      "SUBSCRIPTION_STATE_CANCELED" ||
    user?.subscriptionPlanInfo?.subscriptionState ===
      "SUBSCRIPTION_STATE_ACTIVE" ||
    accessType === "IS_FREE" ||
    (accessType === "IS_DAILY" && isActive) ||
    isGuest
  ) {
    return true;
  }

  return false;
};


export function debounce(func, wait) {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}


