import { Stack } from '@mui/material';
import { Waveform } from '@uiball/loaders'

export default function EightLoader() {
  return (
    <Stack alignItems="center" justifyContent="center" sx={{height: '80vh', width: '100vw'}}>
      <Waveform 
      size={40}
      lineWeight={3.5}
      speed={1} 
      color="white" 
      />
    </Stack>
  );
}
