import { Button, CircularProgress } from "@mui/material";
import React, { useContext } from "react";
import { useState } from "react";
import { ReactComponent as PlaylistPlayIcon } from "../assets/Images/playlist-play-icon.svg";
import { ReactComponent as PlaylistPauseIcon } from "../assets/Images/playlist-pause-icon.svg";
import {
  PUBLISH_CONTENT_CLICKED,
  PUBLISH_CONTENT_ENDED,
  TUNED_IN,
  TUNED_OUT,
} from "../Events/events";
import AuthContext from "../Context/AuthContext";
import { usePlayerContext } from "../Context/PlayerContext";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { getContentAccess } from "../Util/functions";
import { getPublishContentNextEpisode } from "../apis/publishContent";
import { getRssNextEpisode } from "../apis/rss";

const ResumePlayButton = ({ parentData, carouselName, className }) => {
  const [loading, setLoading] = useState(true);

  const authCtx = useContext(AuthContext);
  const {
    currentSong,
    playPauseHandler,
    seriesData,
    setSong,
    isPlaying,
    setEpisodeList,
  } = usePlayerContext();

  const getEpisodeList = async (parentData) => {
    if (parentData?.contentType === "PUBLISH_RECORD") {
      try {
        const data = await getPublishContentNextEpisode(parentData.id, authCtx);
        if (!authCtx.token) {
          return data.Items[0];
        } else {
          return data[0];
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const data = await getRssNextEpisode(parentData.id, authCtx);
        if (!authCtx.token) {
          return data.Items[0];
        } else {
          return data[0];
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getRssEpisodeData = async () => {};

  const playlistPlayHandler = async () => {
    setLoading(true);

    if (
      Object.keys(currentSong)?.length !== 0 &&
      seriesData?.id === parentData?.id
    ) {
      playPauseHandler();
    } else {
      if (parentData?.id) {
        const prevPlayed = await getEpisodeList(parentData);
        setSong(prevPlayed, parentData);
        PUBLISH_CONTENT_CLICKED(
          prevPlayed,
          parentData,
          authCtx?.user,
          carouselName
        );
        TUNED_IN(prevPlayed, parentData, authCtx?.user, carouselName);
      }
    }

    if (currentSong !== null && currentSong?.songId?.length !== 0) {
      TUNED_OUT(
        currentSong,
        seriesData,
        authCtx?.user,
        "SongChanged",
        carouselName
      );
      PUBLISH_CONTENT_ENDED(
        currentSong,
        seriesData,
        authCtx?.user,
        "SongChanged",
        carouselName
      );
    }

    setLoading(false);
  };

  // const contentAccess = getContentAccess(
  //   parentData?.accessType,
  //   prevPlayed?.audioProgressData?.isActive,
  //   prevPlayed?.isGuest,
  //   authCtx?.user
  // );

  let buttonText = "Play";
  buttonText =
    seriesData?.id === parentData?.id && isPlaying ? "Pause " : "Play ";

  if (loading) {
    <Button>
      <CircularProgress sx={{ color: "white" }} />
    </Button>;
  }

  return (
    <Button
      id="playlistButton"
      className={`gta-playButton ${className}`}
      onClick={playlistPlayHandler}
      startIcon={
        seriesData?.id === parentData?.id && isPlaying ? (
          <PlaylistPauseIcon style={{ width: "20px" }} />
        ) : (
          <PlaylistPlayIcon style={{ width: "20px" }} />
        )
      }
    >
      {buttonText}
    </Button>
  );
};

export default ResumePlayButton;
