import { signOut } from "firebase/auth";
import { createContext, useContext, useState } from "react";
import React from "react";
import { auth } from "../config/firebaseConfig";
import secureLocalStorage from "react-secure-storage";
import mixpanel from "mixpanel-browser";

const AuthContext = createContext({
  token: "",
  isLoggedIn: false,
  user: {},
  login: (token, user) => {},
  logout: () => {},
});

export const AuthContextProvider = (props) => {
  const [token, setToken] = useState(null);
  const [user, setUser] = useState({});
  const userIsLoggedIn = !!token;
  // const setAuthCtx = usePlayerStore((state) => state.setAuthCtx);
  // const {audioRef} = usePlayerContext()

  const loginHandler = (token, user) => {
    secureLocalStorage.setItem("token", token);
    secureLocalStorage.setItem("user", user);
    setUser(user);
    setToken(token);
    secureLocalStorage.setItem("guestId", null);

    if (token !== null) {
      mixpanel.identify(user?.id);
      const userProfile = {
        $first_name: user?.firstName,
        $last_name: user?.lastName,
        id: user.id,
        $avatar: user?.avatar,
        $email: user?.email,
        $phone: user?.phone,
      };

      mixpanel.people.set(userProfile);

      // clevertap.onUserLogin.push({
      //   Site: {
      //     "First-name": authCtx?.user?.firstName,
      //     Name: authCtx?.user?.firstName + " " + authCtx?.user?.lastName, // String
      //     Identity: authCtx?.user?.id,
      //     Email: authCtx?.user?.email,
      //     Phone: authCtx?.user?.phone,

      //     "MSG-email": true,
      //     "MSG-push": true,
      //     "MSG-sms": true,
      //     "MSG-whatsapp": true,
      //   },
      // });
    } 
  };

  const logoutHandler = () => {
    signOut(auth)
      .then(() => {
        setToken(null);
        setUser(null);
        mixpanel.reset();
        secureLocalStorage.clear();
        // setAuthCtx({ token: null, user: {} });
        // audioRef?.current?.pause();
      })
      .catch((error) => {
        console.log("something went wrong failed to logged out", error);
      });
  };

  const contextValue = {
    token: token,
    user: user,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
    setToken: setToken,
    setUser: setUser,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export const useAuthCtx = () => {
  const authCtx = useContext(AuthContext);
  return authCtx;
};

export default AuthContext;
