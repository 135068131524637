export const internalLinks = [
  {
    mainTitle: "By Genre",
    content: [
      {
        subTitle: "Horror",
        Links: [
          {
            name: "The Indian Paranormal Podcast",
            Link: "/podcast-shows/horror/the-indian-paranormal-podcast/f009652e-1048-406b-b165-dee4c038f74c",
          },
          {
            name: "Saaya",
            Link: "/audio-stories/horror/saaya/4c737dff-57b5-4fc0-964d-1949af16cb29",
          },
          {
            name: "Bhootiya Saala",
            Link: "/audio-stories/horror/bhootiya-saala/0e658031-c524-44e2-9126-e63c08adfe88",
          },
          {
            name: "Haunted Honeymoon",
            Link: "/audio-stories/horror/haunted-honeymoon/3d97079e-2fd1-4e5f-ad46-2153b2bbc615",
          },
          {
            name: "Today's Horror Story",
            Link: "/audio-stories/horror/todays-horror-story/af9c36f6-f75f-408c-8dc6-c7f23c8df1b6",
          },
          {
            name: "Shakchunni",
            Link: "/audio-stories/horror/shakchunni/22e22554-e747-40a3-9519-1bdc74163058",
          },
          {
            name: "Zoonie",
            Link: "/audio-stories/horror/zoonie/ce7722d1-9fa5-45cc-a618-30b7e2895797",
          },
        ],
      },
      {
        subTitle: "Romance",
        Links: [
          {
            name: "How To Date An Asshole",
            Link: "/audio-stories/love_relationship/how-to-date-an-asshole/eb08611e-130c-4c31-9cd0-e2cbee17547b",
          },
          {
            name: "Love, Ghazal",
            Link: "/audio-stories/love_relationship/love-ghazal/d9db4b17-61a4-4dba-8ca3-7143bc26e73f",
          },
          {
            name: "Sex On The Table",
            Link: "/audio-stories/love_relationship/sex-on-the-table/9be733bb-7728-4bad-ae53-b89faf87e459",
          },
          {
            name: "Back-Up Plan",
            Link: "/audio-stories/love_relationship/backup-plan/e9642d07-4740-4eb5-ac78-d35f48762fda",
          },
          {
            name: "Behad",
            Link: "/audio-stories/love_relationship/behad/3a477616-4f6e-430b-b934-3a41dc64da01",
          },
          {
            name: "Phir Teri Kahani - A Love Story Podcast",
            Link: "/podcast-shows/love_relationship/phir-teri-kahani--a-love-story-podcast/256bf4a5-c9de-48f8-8216-e3934806ab5a",
          },
        ],
      },
      {
        subTitle: "Crime",
        Links: [
          {
            name: "World's Youngest Serial Killer",
            Link: "/audio-stories/crime_thriller/worlds-youngest-serial-killer/50b52f4b-7c24-4d3b-b0b1-243874328ec2",
          },
          {
            name: "The Punia Murder Case",
            Link: "/audio-stories/crime_thriller/the-punia-murder-case-/a2b82983-adb0-40e2-be80-b2e261ae0436",
          },
          {
            name: "Constable: The Rapist",
            Link: "/audio-stories/crime_thriller/constable-the-rapist/da36257b-910d-4b00-84e3-a42b4226596c",
          },
          {
            name: "Dr. Death",
            Link: "/audio-stories/crime_thriller/dr-death/5fe8965a-eb86-40c4-8383-7d64cac91f15",
          },
          {
            name: "Kill Cut Eat: Nithari",
            Link: "/audio-stories/crime_thriller/kill-cut-eat-nithari/565e3363-3fe2-432d-a6d6-39b7666f8f03",
          },
        ],
      },
      {
        subTitle: "Learning",
        Links: [
          {
            name: "100 Days to Marriage",
            Link: "/audio-stories/love_relationship/100-days-to-marriage/bb5c528e-22cc-473c-bf1f-af04c39e433a",
          },
          {
            name: "Yours Confidently",
            Link: "/audio-stories/others/yours-confidently/f3e98318-a845-4fc5-86b6-6090f2d3e9aa",
          },
          {
            name: "25 Ghante",
            Link: "/podcast-shows/others/25-ghante-the-podcast/22b7aadf-ae74-4cb1-85ec-593678b75252",
          },
          {
            name: "Huberman Lab",
            Link: "/podcast-shows/others/huberman-lab/9ec76277-f0eb-4f9a-b914-36d05c0095a5",
          },
        ],
      },
    ],
  },
  {
    mainTitle: "Eight Exclusive",
    content: [
      {
        subTitle: "Trending",
        Links: [
          {
            name: "Indian Scandal League",
            Link: "/audio-stories/others/indian-scandal-league/6cd95748-6d16-411e-9c07-a7d13b7074b8",
          },
          {
            name: "Khalistan : But Why?",
            Link: "/audio-stories/others/khalistan--but-why/bf8460e7-534b-4915-a7bb-17a065ecd2a7",
          },
          {
            name: "Rotten Mango",
            Link: "/podcast-shows/crime_thriller/rotten-mango/f2707666-b642-4f42-9306-bd5fa0f5528c",
          },
          {
            name: "WSJ’s The Future of Everything",
            Link: "/podcast-shows/others/wsjs-the-future-of-everything/44a0bfb1-5cf8-41be-bbeb-4819ee74942c",
          },
        ],
      },
      {
        subTitle: "Most Streamed",
        Links: [
          {
            name: "Kya Woh Sach Tha",
            Link: "/podcast-shows/horror/kya-woh-sach-tha/b0d6f079-9509-4b99-b59c-373a5ab62f8d",
          },
          {
            name: "Mind Reading With Ravindar",
            Link: "/audio-stories/others/mind-reading-with-ravindar/555264e2-d449-4c58-a8f1-b4b92887e63d",
          },
          {
            name: "The Silent Killer",
            Link: "/audio-stories/crime_thriller/the-silent-killer/069527b9-c12f-4ca7-a920-1727393fd7ad",
          },
          {
            name: "Ek Kahani Aisi Bhi",
            Link: "/podcast-shows/horror/ek-kahani-aisi-bhi/82fe1526-1bd6-4ee7-9713-86222437a0f5",
          },
        ],
      },
      {
        subTitle: "Top 8 Today",
        Links: [
          {
            name: "Aaya Police",
            Link: "/audio-stories/crime_thriller/aaya-police/fcdde9c2-f25e-4f72-ae10-88fa6d9b2a6f",
          },
          {
            name: "Mahabharat",
            Link: "/podcast-shows/others/mahabharat/ef91f68f-1b4f-42d4-9c3e-d0eee4f6f840",
          },
          {
            name: "Khauf",
            Link: "/podcast-shows/horror/khauf-hindi-horror-podcast/68851d8c-d1fe-4989-b636-e0815702baea",
          },
          {
            name: "Truck No 1981",
            Link: "/audio-stories/crime_thriller/truck-no-1981/dcbec465-764b-4d05-bbdb-4ec3d45bd2fa",
          },
        ],
      },
      {
        subTitle: "Popular Shows",
        Links: [
          {
            name: "Yes Sir I Killed My Dad",
            Link: "/audio-stories/crime_thriller/yes-sir-i-killed-my-dad/4c419dc0-7ec4-4288-b311-0bd18891a28e",
          },
          {
            name: "The Killer",
            Link: "/audio-stories/crime_thriller/the-killer/06eaa79e-fedb-47ae-8204-b3c076398560",
          },
          {
            name: "Mr. Undercover",
            Link: "/audio-stories/crime%20&%20thriller/mr-undercover/6d7ed0d5-6e37-44a7-be07-f25fbb395650",
          },
        ],
      },
    ],
  },
  {
    mainTitle: "By Category",
    content: [
      {
        subTitle: "Podcast Shows",
        Links: [
          {
            name: "Horror Podcast Hindi",
            Link: "/podcast-shows/horror/horror-podcast-hindi/632b4e56-24a5-4e86-99f3-1d1209fdde8b",
          },
          {
            name: "Bhagavad Gita",
            Link: "/podcast-shows/others/bhagavad-gita-hindi/ebdff88b-b4d0-4665-8a4b-0adf8375e4b2",
          },
          {
            name: "India Classified",
            Link: "/podcast-shows/others/india-classified/569a7533-5b0f-422d-9fe4-e1c85924b4e7",
          },
          {
            name: "Mahabharat",
            Link: "/podcast-shows/others/mahabharat/ef91f68f-1b4f-42d4-9c3e-d0eee4f6f840",
          },
          {
            name: "The Ranveer Show",
            Link: "/podcast-shows/others/the-ranveer-show/865e7c88-3fb9-4d99-aff7-8dd392c272cd",
          },
          {
            name: "On Purpose with Jay Shetty",
            Link: "/podcast-shows/others/on-purpose-with-jay-shetty/a20d5599-da91-490c-945b-acf289cd994a",
          },
          {
            name: "Chanakya Neeti",
            Link: "/podcast-shows/others/chanakya-neeti-sutra-sahit/5b01b15e-6eed-4e8a-9db6-e92b6f1e9c15",
          },
          {
            name: "Shiva - Narrated by Jackie Shroff",
            Link: "/podcast-shows/others/shiva--narrated-by-jackie-shroff/49201c9c-6204-4a78-92e7-513c7695ae9c",
          },
        ],
      },
      {
        subTitle: "Audio Stories",
        Links: [
          {
            name: "The Indian Paranormal Podcast",
            Link: "/podcast-shows/horror/the-indian-paranormal-podcast/f009652e-1048-406b-b165-dee4c038f74c",
          },
          {
            name: "Baarish : A Psychological Thriller",
            Link: "/audio-stories/horror/baarish--a-psychological-thriller/1b63040a-b406-44d6-a452-d76e166c4581",
          },
          {
            name: "Aaya Police",
            Link: "/audio-stories/crime_thriller/aaya-police/fcdde9c2-f25e-4f72-ae10-88fa6d9b2a6f",
          },
          {
            name: "Mil Gaye Chora Chori",
            Link: "/audio-stories/love_relationship/mil-gaye-chora-chori/aefb9ac7-2997-4ded-a802-9fec9ac9442d",
          },
          {
            name: "Apraadh",
            Link: "/audio-stories/crime_thriller/apraadh/6c7bdd31-e0d5-4070-8d04-1f48befb654b",
          },
          {
            name: "The Lift - India's First Audio Film",
            Link: "/audio-stories/horror/the-lift--indias-first-audio-film/a0686108-4dcf-4561-b59c-834f49509214",
          },
          {
            name: "The Rented Room",
            Link: "/audio-stories/horror/the-rented-room-/ef542780-acd0-4f8a-9bd8-068f494e98ef",
          },
          {
            name: "Rich Dad Poor Dad",
            Link: "/audio-stories/learning-&-development/rich-dad-poor-dad/6111b003-a480-477f-a56e-31ece2bdd745",
          },
        ],
      },
    ],
  },
];
