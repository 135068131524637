import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as ArrowIcon } from "../../assets/Images/dropdown-arrow-icon.svg";
import { languageList, primaryGenreList } from "../../Util/Data";
import { genreBreakFormatter } from "../../Util/FormatData/Formatter";

const NavbarMenu = () => {
  return (
    <div className="navbarMenu">
      <Link to="/">Home</Link>
      <div className="dropdown">
        <button className="dropbtn">
          Genre
          <span>
            <ArrowIcon />
          </span>
        </button>
        <div className="dropdown-content-wrapper">
          <div className="dropdown-content-big">
            {primaryGenreList.map((item, index) => (
              <Link to={`genre/${genreBreakFormatter(item)}`} key={index}>
                {item}
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className="dropdown">
        <button className="dropbtn">
          Language
          <span>
            <ArrowIcon />
          </span>
        </button>
        <div className="dropdown-content-wrapper">
          <div className="dropdown-content">
            {languageList.map((item, index) => (
              <Link
                to={`language/${item.English.toLowerCase()
                  .split(/[ _]/)
                  .join("-")}`}
                key={index}
              >
                {item.English}
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className="dropdown">
        <button className="dropbtn">
          Category
          <span>
            <ArrowIcon />
          </span>
        </button>
        <div className="dropdown-content-wrapper">
          <div className="dropdown-content">
            <Link to="/category/audio-stories">Audio Stories</Link>
            <Link to="/category/podcast-shows">Podcast Shows</Link>
          </div>
        </div>
      </div>
      {/* <NavLink href="#home">Top Shows</NavLink> */}
    </div>
  );
};

export default NavbarMenu;
