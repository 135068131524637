import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Swiper, SwiperSlide } from "swiper/react";

const SquareCarouselSkeleton = () => {
  return (
    <SkeletonTheme baseColor="#404040" highlightColor="#606060">
      <div className="carousel-container-wrapper">
        <div className="heading">
          <span>
            <Skeleton height={10} />
          </span>
          <a href="#">
            <Skeleton height={10} />
          </a>
        </div>
        <Swiper
          grabCursor={false}
          loop={false}
          slidesPerView={"auto"}
          allowTouchMove={false}
          className={`carousel-container`}
        >
          {Array.from({ length: 10 }, (_, i) => (
            <SwiperSlide className="square-carousel-card">
              <Skeleton className="square-carousel-card" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </SkeletonTheme>
  );
};

export default SquareCarouselSkeleton;
