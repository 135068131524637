import React from "react";
import { ReactComponent as Xicon } from "../../assets/Images/x-icon.svg";
import { Link } from "react-router-dom";
import {
  categoryFormatter,
  contentNameFormtter,
  genreBreakFormatter,
} from "../../Util/FormatData/Formatter";
import Image from "../../Ui/Image";

const Top10CarouselCard = ({ publishCT, index, carouselName }) => {
  return (
    <Link
      to={`/${categoryFormatter(publishCT?.style)}/${genreBreakFormatter(
        publishCT.primaryGenre
      )}/${contentNameFormtter(publishCT.name)}/${publishCT.id}`}
      state={{ carouselName: carouselName }}
    >
      <div className="top-10-carousel-card">
        <div className="img-wrapper">
          <Image
            src={publishCT?.bannerSquare?.md || publishCT?.bannerSquare?.sm}
            alt={publishCT?.banner1Alt || publishCT?.about}
          />
          <span className="number">{index + 1}</span>
          {publishCT?.contentType === "PUBLISH_RECORD" && (
            <div className="x-icon">
              <Xicon color="red" background="red" />
            </div>
          )}
        </div>
        <p className="title">{publishCT?.name}</p>
      </div>
    </Link>
  );
};

export default Top10CarouselCard;
