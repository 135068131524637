import Navbar from "./components/Navbar/Navbar";
import LoginModal from "./components/Modal/Login/LoginModal";
import MusicPlayer from "./components/MusicPlayer";
import { useContext, useEffect, useState } from "react";
import AuthContext from "./Context/AuthContext";
import secureLocalStorage from "react-secure-storage";
import EightLoader from "./components/Backdrop/EightLoader";
import { APP_OPEN, First_App_Open } from "./Events/events";
import mixpanel from "mixpanel-browser";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import Router from "./Router/router";
import MobileBottomNavbar from "./components/Navbar/MobileBottomNavbar";
import InfoModal from "../src/components/Modal/InfoModal";
import PremiumModal from "./components/Modal/PremiumModal";
function isMobileScreen() {
  return window.innerWidth <= 650; // Change the threshold as per your requirements
}

const checkUser = async (initToken, authCtx) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_EIGHT_NETWORK_API}/api/user/self`,
      {
        headers: {
          Authorization: `Bearer ${initToken}`,
        },
      }
    );
    authCtx.login(initToken, data);
  } catch (err) {
    authCtx.logout();
    alert("You have Been Logout");
  }
};

function App() {
  const [open, setOpen] = useState(isMobileScreen());
  const [loading, setloading] = useState(true);
  const authCtx = useContext(AuthContext);
  const [searchInput, setsearchInput] = useState("");
  const tempUserId = uuidv4();

  useEffect(() => {
    const initToken = secureLocalStorage.getItem("token");
    if (initToken) {
      checkUser(initToken, authCtx);
    } else {
      secureLocalStorage.setItem("guestId", tempUserId);
    }

    mixpanel.init(process.env.REACT_APP_MIX_PANEL_PROJECT_TOKEN, {
      debug: false,
      track_pageview: true,
      persistence: "localStorage",
    });

    const isFirstTime = localStorage.getItem("isFirstTime");
    if (isFirstTime?.length > 1) {
      APP_OPEN(authCtx?.user);
    } else {
      First_App_Open(authCtx?.user);
      localStorage.setItem("isFirstTime", false);
    }

    setloading(false);
    // return () => {
    //   if (
    //     currentSong &&
    //     currentSong &&
    //     currentSong.songId &&
    //     currentSong.songId?.length > 1
    //   ) {
    //     PUBLISH_CONTENT_ENDED(
    //       currentSong,
    //       seriesData,
    //       authCtx?.user,
    //       "WindowTabClosed",
    //       carouselName
    //     );
    //     TUNED_OUT(
    //       currentSong,
    //       seriesData,
    //       authCtx?.user,
    //       "WindowTabClosed",
    //       carouselName
    //     );
    //   }
    // };
  }, []);

  // useEffect(() => {
  //   let deviceToken;
  //   async function requestPermission() {
  //     const permission = await Notification.requestPermission();
  //     if (permission === "granted") {
  //       const token = await getToken(messaging, {
  //         vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
  //       });
  //       deviceToken = token;
  //     } else if (permission === "denied") {
  //     }
  //   }

  //   requestPermission();
  //   clevertap.notifications.push({
  //     titleText: "Would you like to receive Push Notifications?",
  //     bodyText:
  //       "We promise to only send you relevant content and give you updates on your transactions",
  //     okButtonText: "Sign me up!",
  //     rejectButtonText: "No thanks",
  //     okButtonColor: "#F28046",
  //     serviceWorkerPath: "/cleavertap_sw.js",
  //   });
  //   clevertap.spa = true;

  //   return () => {
  //     clevertap.notifications.removeDeviceToken(deviceToken, (response) => {
  //       if (response.status === "success") {
  //       } else {
  //         console.error(
  //           "Error unsubscribing from push notifications:",
  //           response
  //         );
  //       }
  //     });
  //   };
  // }, []);

  if (loading) {
    return <EightLoader />;
  }

  return (
    <>
      <Navbar setOpen={setOpen} open={open} setsearchInput={setsearchInput} />
      {<MobileBottomNavbar />}

      <div className="main-content">
        <MusicPlayer />
        <Router searchInput={searchInput} />
      </div>
      <LoginModal />
      <InfoModal />
      <PremiumModal />
    </>
  );
}

export default App;
