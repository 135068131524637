import React, { useEffect, useState } from "react";
import ThreeDCarousel from "../../components/Carousel/ThreeDCarousel";
import SquareCarousel from "../../components/Carousel/SquareCarousel";
import { useAuthCtx } from "../../Context/AuthContext";

import Top10Carousel from "../../components/Carousel/Top10Carousel";
import ContinueLiteningCarousel from "../../components/Carousel/ContinueListeningCarousel";
import Footer from "../../components/Footer/Footer";
import InfiniteScroll from "react-infinite-scroll-component";
import { Helmet } from "react-helmet-async";
import PortraitCarousel from "../../components/Carousel/PortraitCarousel";
import SquareCarouselSkeleton from "../../components/skeleton/carousel/SquareCarouselSkeleton";
import GridViewSixCarousel from "../../components/Carousel/GridViewSixCarousel";
import { getServerDrivenCarousel } from "../../apis";
import SquarewithRatingCarousel from "../../components/Carousel/SquarewithRatingCarousel";
import GridViewEightCarousel from "../../components/Carousel/GridViewEightCarousel";
import useIsMobile from "../../hooks/useIsMobile";
import GridViewEightCarouselMobile from "../../components/Carousel/GridViewEightCarouselMobile";
import { useInfiniteQuery } from "react-query";

const carouselToDisplay = (value, isMobile) => {
  switch (value.structure) {
    case "GRIDVIEWTOP10":
      return <Top10Carousel carousel={value} key={value.id} />;
    case "GRIDVIEW1":
      return <SquarewithRatingCarousel carousel={value} key={value.id} />;
    case "GRIDVIEW2":
      return <SquareCarousel carousel={value} key={value.id} />;
    case "GRIDVIEW3":
      return <SquareCarousel carousel={value} key={value.id} />;
    case "GRIDVIEW4":
      return <SquareCarousel carousel={value} key={value.id} />;
    case "GRIDVIEW5":
      return;
    case "GRIDVIEW6":
      return <GridViewSixCarousel carousel={value} key={value.id} />;
    case "GRIDVIEW7":
      return <SquarewithRatingCarousel carousel={value} key={value.id} />;
    case "GRIDVIEW8":
      return isMobile ? (
        <GridViewEightCarouselMobile carousel={value} key={value.id} />
      ) : (
        <GridViewEightCarousel carousel={value} key={value.id} />
      );
    case "PORTRAIT":
      return <PortraitCarousel carousel={value} key={value.id} />;
    case "SQUARE":
      return <SquareCarousel carousel={value} key={value.id} />;
    case "VIDEO1":
      return;
    case "VIDEO2":
      return;
    case "LISTVIEWSQUARE":
      return <SquareCarousel carousel={value} key={value.id} />;
    case "LANDSCAPE":
      return;
    default:
      return <SquareCarousel carousel={value} key={value.id} />;
  }
};
const HomePage = () => {
  const [carousel, setCarousel] = useState([]);
  const [loading, setloading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const authCtx = useAuthCtx();
  const [offset, setOffset] = useState(0);
  const isMobile = useIsMobile();

  // const {
  //   data: carousel,
  //   isLoading: loading,
  //   isError,
  //   fetchNextPage,
  // } = useInfiniteQuery({
  //   queryKey: ["serverDrivenCarousel", authCtx],
  //   queryFn: () => getServerDrivenCarousel(offset, authCtx),
  // });

  useEffect(() => {
    setOffset(0);
    const fetchCarousel = async () => {
      try {
        const data = await getServerDrivenCarousel(0, authCtx);
        setCarousel(data);
        setOffset(3);
        if (data.length === 0) {
          setHasMore(false);
        }
      } catch (err) {
        console.error("Error fetching carousel:", err);
      }
    };

    fetchCarousel();

    setTimeout(() => {
      setloading(false);
    }, [1000]);
  }, [authCtx.token]);

  const LoadDynamicCarousel = async () => {
    try {
      const data = await getServerDrivenCarousel(
        offset,
        authCtx,
        carousel.LastEvaluedkey
      );
      setCarousel([...carousel, ...data]);
      setOffset((prev) => prev + 3);
      if (data.length === 0) {
        setHasMore(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  console.log(carousel);
  return (
    <>
      <Helmet>
        <title>Eight - Podcasts, Stories, Live | Download Now</title>
        <meta
          name="description"
          content={`Listen to India's best audio stories, podcasts and live shows. Listen for free on web & app. Download the app now.
          `}
        />

        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="Landing-page-container">
        <ThreeDCarousel />
        {authCtx.token && authCtx.token.length > 0 && (
          <ContinueLiteningCarousel />
        )}

        {loading ? (
          <>
            <SquareCarouselSkeleton />
            <SquareCarouselSkeleton />
            <SquareCarouselSkeleton />
          </>
        ) : (
          <InfiniteScroll
            dataLength={carousel?.length || 0}
            next={LoadDynamicCarousel}
            hasMore={hasMore}
            loader={
              <p
                style={{
                  margin: "10px 0px",
                  fontFamily: "Poppins",
                  textAlign: "center",
                }}
              >
                Loading...
              </p>
            }
            scrollThreshold={0.2}
          >
            {carousel &&
              carousel?.length > 0 &&
              carousel?.map((data, index) => carouselToDisplay(data, isMobile))}
          </InfiniteScroll>
        )}
      </div>
      {!loading && <Footer />}
    </>
  );
};

export default HomePage;
