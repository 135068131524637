import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards, EffectFade, Navigation } from "swiper/modules";
import { useAuthCtx } from "../../Context/AuthContext";
import axios from "axios";
import SquareCarouselSkeleton from "../skeleton/carousel/SquareCarouselSkeleton";
import { ReactComponent as CarouselBackArrow } from "../../assets/Images/carousel-back-arrow.svg";
import { ReactComponent as CarouselNextArrow } from "../../assets/Images/carousel-next-arrow.svg";
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/effect-fade";
import {
  StreamCountFormatter,
  categoryFormatter,
  contentNameFormtter,
  genreBreakFormatter,
} from "../../Util/FormatData/Formatter";
import { ReactComponent as RatingIcon } from "../../assets/Images/rating-icon.svg";
import parse from "html-react-parser";
import ResumePlayButtonHome from "../../Ui/ResumePlayButtonHome";
import { Link } from "react-router-dom";

const GridViewEightCarousel = ({ carousel }) => {
  const [carouselData, setCarouselData] = useState();
  const authCtx = useAuthCtx();
  const [loading, setLoading] = useState(true);

  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const swiperRef = useRef(null);
  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.on("activeIndexChange", (e) => {
        const Index = e.realIndex;
        setActiveSlideIndex(Index);
      });
    }
  }, [swiperRef.current]);

  useEffect(() => {
    setLoading(true);
    const fetchCarouselData = async () => {
      const { data } = await axios.get(carousel.data, {
        headers: { Authorization: `Bearer ${authCtx.token}` },
      });
      setCarouselData(data);

      setTimeout(() => {
        setLoading(false);
      }, [1000]);
    };
    fetchCarouselData();
  }, []);
  console.log(carouselData);

  if (loading) {
    <SquareCarouselSkeleton />;
  }
  if (!carouselData) {
    return;
  }

  return (
    <div className=" grid-view-eight-carousel-container">
      <div
        className={`custom-prev-button slider-arrow`}
        id={`custom-prev-button-${carouselData?.title}`}
        style={
          swiperRef?.current?.isBeginning
            ? { visibility: "hidden" }
            : { visibility: "visible", cursor: "pointer" }
        }
        onClick={() => swiperRef.current.slidePrev()}
      >
        <CarouselBackArrow style={{ height: "100%", width: "100%" }} />
      </div>


      <Swiper
        effect="cards"
        grabCursor={true}
        loop={false}
        modules={[EffectCards, EffectFade]}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        className="grid-view-eight-carousel"
      >
        {carouselData?.content?.map((item) => {
          const link = `/${categoryFormatter(
            item?.style
          )}/${genreBreakFormatter(item?.primaryGenre)}/${contentNameFormtter(
            item?.name
          )}/${item?.id}`;

          return (
            <SwiperSlide key={item.id}>
              <Link to={link}>
                <div key={item.id}>
                  <img src={item?.bannerSquare?.md} alt="" />
                </div>
              </Link>
            </SwiperSlide>
          );
        })}
      </Swiper>

      <div className="shows-info">
        <h2 className="carousel-title">{carouselData?.title}</h2>
        <h3 className="title">
          {carouselData?.content[activeSlideIndex]?.name}
        </h3>
        <p className="about">
          {parse(carouselData?.content[activeSlideIndex]?.about || " ")}
        </p>

        <p className="rating-plays">
          {carouselData?.content[activeSlideIndex]?.review &&
            carouselData?.content[activeSlideIndex]?.reviewersCount && (
              <>
                <RatingIcon />{" "}
                {(
                  carouselData?.content[activeSlideIndex]?.review /
                  carouselData?.content[activeSlideIndex]?.reviewersCount
                ).toFixed(1)}{" "}
                |
              </>
            )}{" "}
          {StreamCountFormatter(
            carouselData?.content[activeSlideIndex]?.streams
          )}{" "}
          Plays
        </p>

        {/* <p className="rating gasp-text">
        {ShowSwiperData[activeSlideIndex].rating}
      </p> */}

        {/* <Link href={carouselData[activeSlideIndex].link} target="_blank"> */}
        <div className="btn">
          <ResumePlayButtonHome
            parentData={carouselData?.content[activeSlideIndex]}
            carouselName={carouselData?.carouselName}
            className={"play-button"}
          >
            Play
          </ResumePlayButtonHome>
        </div>
        {/* </Link> */}
      </div>

      <div
        className="custom-next-button slider-arrow"
        id={`custom-next-button-${carouselData?.title}`}
        style={
          swiperRef?.current?.isEnd
            ? { visibility: "hidden" }
            : { visibility: "visible" }
        }
        onClick={() => swiperRef.current.slideNext()}
      >
        <CarouselNextArrow style={{ height: "100%", width: "100%" }} />
      </div>
    </div>
  );
};

export default GridViewEightCarousel;
