


// var nVer = navigator?.appVersion;
// var nAgt = navigator?.userAgent;
var browserName  = navigator?.appName;
var fullVersion  = ''+parseFloat(navigator?.appVersion); 
var majorVersion = parseInt(navigator?.appVersion,10);
// var nameOffset,verOffset,ix;


// function getBrowserInfo() {
//     const nAgt = navigator?.userAgent;
//     let browserName = navigator?.appName;
//     let fullVersion = '' + parseFloat(navigator?.appVersion);
//     let majorVersion = parseInt(navigator?.appVersion, 10);
//     let verOffset;
  
//     if ((verOffset = nAgt?.indexOf("OPR")) !== -1) {
//       browserName = "Opera";
//       fullVersion = nAgt?.substring(verOffset + 4);
//       if ((verOffset = nAgt?.indexOf("Version")) !== -1)
//         fullVersion = nAgt?.substring(verOffset + 8);
//     } else if ((verOffset = nAgt?.indexOf("Edg")) !== -1) {
//       browserName = "Microsoft Edge";
//       fullVersion = nAgt?.substring(verOffset + 4);
//     } else if ((verOffset = nAgt?.indexOf("MSIE")) !== -1) {
//       browserName = "Microsoft Internet Explorer";
//       fullVersion = nAgt?.substring(verOffset + 5);
//     } else if ((verOffset = nAgt?.indexOf("Chrome")) !== -1) {
//       browserName = "Chrome";
//       fullVersion = nAgt?.substring(verOffset + 7);
//     } else if ((verOffset = nAgt?.indexOf("Safari")) !== -1) {
//       browserName = "Safari";
//       fullVersion = nAgt?.substring(verOffset + 7);
//       if ((verOffset = nAgt?.indexOf("Version")) !== -1)
//         fullVersion = nAgt?.substring(verOffset + 8);
//     } else if ((verOffset = nAgt?.indexOf("Firefox")) !== -1) {
//       browserName = "Firefox";
//       fullVersion = nAgt?.substring(verOffset + 8);
//     } else if (
//       (nameOffset = nAgt?.lastIndexOf(" ") + 1) <
//       (verOffset = nAgt?.lastIndexOf("/"))
//     ) {
//       browserName = nAgt?.substring(nameOffset, verOffset);
//       fullVersion = nAgt?.substring(verOffset + 1);
//       if (
//         browserName.toLowerCase() === browserName.toUpperCase()
//       ) {
//         browserName = navigator?.appName;
//       }
//     }
  
//     // Trim the fullVersion string at semicolon/space if present
//     if ((ix = fullVersion.indexOf(";")) !== -1)
//       fullVersion = fullVersion.substring(0, ix);
//     if ((ix = fullVersion.indexOf(" ")) !== -1)
//       fullVersion = fullVersion.substring(0, ix);
  
//     majorVersion = parseInt("" + fullVersion, 10);
//     if (isNaN(majorVersion)) {
//       fullVersion = "" + parseFloat(navigator?.appVersion);
//       majorVersion = parseInt(navigator?.appVersion, 10);
//     }
  
//     return {
//       browserName: browserName,
//       fullVersion: fullVersion,
//       majorVersion: majorVersion,
//     };
//   }


 const userAgent = navigator?.userAgent;

// Function to get OS name
function getOS() {
  if (userAgent.indexOf("Win") !== -1) return "Windows";
  if (userAgent.indexOf("Mac") !== -1) return "MacOS";
  if (userAgent.indexOf("Linux") !== -1) return "Linux";
  if (userAgent.indexOf("Android") !== -1) return "Android";
  if (userAgent.indexOf("iOS") !== -1) return "iOS";
  return "Unknown";
}

// Function to get OS version
function getOSVersion() {
  const os = getOS();
  const osVersionMatch = userAgent.match(new RegExp(`${os} ([0-9_]+)`));

  if (osVersionMatch) {
    return osVersionMatch[1].replace(/_/g, ".");
  }
  
  return "Unknown";
}







// Usage
// const os = getOS();
// const osVersion = getOSVersion();
// const {browserName, fullVersion, majorVersion,} = getBrowserInfo()



 export {browserName, fullVersion, majorVersion,  getOS, getOSVersion}