import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import axios from "axios";
import AuthContext from "./AuthContext";
import { loginModal } from "../components/Modal/Login/LoginModal";
import { PUBLISH_CONTENT_ENDED, TUNED_IN, TUNED_OUT } from "../Events/events";
import { getContentAccess } from "../Util/functions";
import {
  getPublishContentChildList,
  getPublishContentNextEpisode,
} from "../apis/publishContent";
import { useModalStore } from "../hooks/useModal";
import Hls from "hls.js";
import { getRssEpisodeList } from "../apis/rss";
import { getStreamingHeaders } from "../apis/index";

const PlayerContext = createContext();

export const usePlayerContext = () => {
  return useContext(PlayerContext);
};

export const PlayerProvider = ({ children }) => {
  const audioRef = useRef();
  const [episodeList, setEpisodeList] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentSong, setCurrentSong] = useState({});
  const [currentSongId, setCurrentSongId] = useState(undefined);
  const [muted, setMuted] = useState(false);
  const [volume, setVolume] = useState(60);
  const [seriesData, setSeriesData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [carouselName, setCarouselName] = useState("");
  const [currentTime, setCurrentTime] = useState();
  const [duration, setDuration] = useState();
  const [progress, setProgress] = useState();
  const authCtx = useContext(AuthContext);
  const { onOpen } = useModalStore();

  useEffect(() => {
    if (currentSongId && Object.keys(currentSong)?.length !== 0) {
      var root = document.querySelector(":root");
      root.style.setProperty("--music-player-height", "75px");
    }
  }, [currentSongId]);

  useEffect(() => {
    const handleKeyUp = (event) => {
      if (event.code === "Space") {
        event.preventDefault();
        playPauseHandler();
      }
      if (event.keyCode === 39) {
        event.preventDefault();
        tenSForward();
      }

      if (event.keyCode === 37) {
        event.preventDefault();
        tenSBackward();
      }
    };

    if (currentSong && currentSong.audio) {
      document.addEventListener("keyup", handleKeyUp);
    }

    return () => {
      if (currentSong && currentSong.audio) {
        document.removeEventListener("keyup", handleKeyUp);
      }
    };
  }, [currentSongId]);

  useEffect(() => {
    const loadAudio = async () => {
      const playerRef = document.getElementById("player");
      if (Hls.isSupported()) {
        var hls = new Hls();
        if (currentSong.audio) {
          if (currentSong?.audio?.endsWith(".m3u8")) {
            // const tempAudio =
            //   "https://d13x2ot3fgfff9.cloudfront.net/d358fafe-784b-47c5-8b2c-060b4cc5362f.mp3";
            // const tempAudioTest =
            //   "https://d13x2ot3fgfff9.cloudfront.net/hls/d358fafe-784b-47c5-8b2c-060b4cc5362f.m3u8";

            // const { stream_key, stream_policy } = await getStreamingHeaders(
            //   tempAudio,
            //   authCtx
            // );

            // const customHeaders = {
            //   stream_key:
            //     stream_key || "Gwbj+miSFF6E6ly6pZh29C0SRJ1CRdCfiy1mQLLvo+w=",
            //   stream_policy:
            //     stream_policy ||
            //     "eyJ1cmwiOiIvaGxzLyoiLCJleHBpcmVzIjoxNzEwOTI1ODIyfQ==",
            // };
            // console.log(customHeaders);

            // hls.config.xhrSetup = (xhr, url) => {
            //   xhr.open("GET", url, true);
            //   for (const header in customHeaders) {
            //     xhr.setRequestHeader(header, customHeaders[header]);
            //   }
            // };

            hls.loadSource(currentSong?.audio);
            hls.attachMedia(playerRef);
          } else {
            playerRef.src = currentSong.audio;
          }
        }
        audioRef.current = playerRef;
        if (currentSong?.audioProgressData) {
          const { listenedDuration } = currentSong.audioProgressData;
          audioRef.current.currentTime = listenedDuration;
          let prgress = (listenedDuration / currentSong.duration) * 100;
          setProgress(prgress);
        }
      }
    };
    try {
      if (currentSong.songId) {
        loadAudio();
        playPauseHandler();
        if (currentSong?.songId && !currentSong.isGuest && !authCtx.token) {
          return;
        } else {
          updateStreamCount();
          console.log(currentSongId);
        }
      }
    } catch (err) {}
  }, [currentSongId]);

  const setSong = (song, seriesData) => {
    if (
      !getContentAccess(
        seriesData?.accessType,
        song?.audioProgressData?.isActive,
        song?.isGuest,
        authCtx.user
      )
    ) {
      onOpen("INFO_MODAL", song);
      return;
    }
    setDuration(song.duration);
    setCurrentSong(song);
    setCurrentSongId(song.songId);

    if (seriesData) {
      setSeriesData(seriesData);
    }

    if (seriesData.type === "PUBLISH_RECORD_SINGLE") {
      setEpisodeList(null);
    }
  };

  const playPauseHandler = () => {
    if (
      !getContentAccess(
        seriesData.accessType,
        currentSong?.audioProgressData?.isActive,
        currentSong?.isGuest,
        authCtx.user
      )
    ) {
      onOpen("INFO_MODAL", currentSong);
      return;
    }
    if (
      currentSong?.songId?.length > 0 &&
      (currentSong.isGuest === false ||
        currentSong.isGuest === undefined ||
        currentSong.isGuest === null) &&
      (authCtx.token === null || authCtx.token === undefined)
    ) {
      loginModal(true);
      setIsPlaying(false);
      return;
    }
    if (!audioRef?.current?.paused) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
  };

  const updateStreamCount = async () => {
    if (seriesData?.contentType === "PUBLISH_RECORD") {
      try {
        if (authCtx.token) {
          const URL = `${process.env.REACT_APP_EIGHT_PUBLISH_CONTENT_API}/api/audio/library/stream/${currentSong?.songId}`;
          await axios.patch(URL, {
            headers: {
              Authorization: `Bearer ${authCtx.token}`,
            },
          });
        } else if (authCtx.token === null || authCtx.token === undefined) {
          const URL = `${process.env.REACT_APP_EIGHT_EXPOSED_API}/api/v1/audio/library/stream/${currentSong?.songId}`;
          axios.patch(URL);
        }
      } catch (err) {}
    } else {
      try {
        if (authCtx.token && authCtx.token.length > 0) {
          const URL = `${process.env.REACT_APP_EIGHT_PUBLISH_CONTENT_API}/api/rss/stream/${currentSong?.songId}`;
          await axios.patch(URL, {
            headers: {
              Authorization: `Bearer ${authCtx.token}`,
            },
          });
        } else {
          const URL = `${process.env.REACT_APP_EIGHT_EXPOSED_API}/api/v1/rss/stream/${currentSong?.songId}`;
          await axios.patch(URL);
        }
      } catch (err) {}
    }
  };

  const timelineSlideHandler = (value) => {
    if (
      !getContentAccess(
        seriesData?.accessType,
        currentSong?.audioProgressData?.isActive,
        currentSong?.isGuest,
        authCtx.user
      )
    ) {
      onOpen("INFO_MODAL", currentSong);
      return;
    }

    if (currentSong.songId > 0 && !currentSong.isGuest && !authCtx.token) {
      loginModal(true);
      return { currentSong, audioRef };
    }
    setProgress((audioRef.current.currentTime / duration) * 100);
    audioRef.current.currentTime = (value / 100) * duration;
  };

  const audioOnPlaying = () => {
    const currentTime = audioRef.current.currentTime;
    setProgress((currentTime / duration) * 100);
    setCurrentTime(currentTime);
  };

  const volumeChangeHandler = (_, value) => {
    audioRef.current.volume = value / 100;
    setVolume(value);
    if (value === 0) {
      setMuted(true);
    } else {
      setMuted(false);
    }
  };

  const volumnChangeEvent = (value) => {
    audioRef.current.volume = value / 100;
    setVolume(value);
  };
  console.log("episodeList", episodeList);
  const onEnd = async () => {
    updateStreamCount();

    TUNED_OUT(
      currentSong,
      seriesData,
      authCtx?.user,
      "SongEnded",
      carouselName
    );

    PUBLISH_CONTENT_ENDED(
      currentSong,
      seriesData,
      authCtx.user,
      "SongEnded",
      carouselName
    );

    if (episodeList.Items) {
      let index = episodeList?.Items?.findIndex(
        (item) => item.songId === currentSong.songId
      );
      if (episodeList?.Items[index + 1]) {
        console.log("episode list", episodeList);
        setSong(episodeList.Items[index + 1], seriesData);
      }

      if (index === episodeList.Items.length - 1) {
        if (seriesData.contentType === "PUBLISH_RECORD") {
          getPublishContentChildList(
            seriesData.id,
            10,
            authCtx,
            episodeList.LastEvaluatedKey
          ).then((data) => {
            if (data.Items.length > 0) {
              setSong(data.Items[0], seriesData);
            }
            setEpisodeList(data);
          });
        } else if (seriesData.contentType === "RSS") {
          getRssEpisodeList(
            seriesData.id,
            10,
            authCtx,
            episodeList.LastEvaluatedKey
          ).then((data) => {
            if (data.Items.length > 0) {
              setSong(data.Items[0], seriesData);
            }
            setEpisodeList(data);
          });
        }
      }
    } else {
      if (seriesData.contentType === "PUBLISH_RECORD") {
        const publishContentList = await getPublishContentChildList(
          seriesData.id,
          10,
          authCtx
        );

        let currentSongIndex = publishContentList.Items.findIndex(
          (item) => item.songId === currentSong.songId
        );
        let combinedPublishContent = publishContentList;
        while (
          currentSongIndex == -1 &&
          currentSongIndex === combinedPublishContent.Items.length - 1
        ) {
          const nextPulishContent = await getPublishContentChildList(
            seriesData.id,
            10,
            authCtx,
            publishContentList.LastEvaluatedKey
          );
          combinedPublishContent = {
            ...nextPulishContent,
            Items: [
              ...combinedPublishContent.Items,
              ...nextPulishContent.Items,
            ],
          };
          currentSongIndex = combinedPublishContent.Items.findIndex(
            (item) => item.songId === currentSong.songId
          );
        }

        setSong(combinedPublishContent.Items[currentSongIndex + 1], seriesData);
        setEpisodeList(combinedPublishContent);
      } else if (seriesData.contentType === "RSS") {
        const rssList = await getRssEpisodeList(seriesData.id, 10, authCtx);

        let currentSongIndex = rssList.Items.findIndex(
          (item) => item.songId === currentSong.songId
        );
        let combinedRssList = rssList;
        while (
          currentSongIndex == -1 &&
          currentSongIndex === combinedRssList.Items.length - 1
        ) {
          const nextRss = await getRssEpisodeList(
            seriesData.id,
            10,
            authCtx,
            rssList.LastEvaluatedKey
          );
          combinedRssList = {
            ...nextRss,
            Items: [...combinedRssList.Items, ...nextRss.Items],
          };
          currentSongIndex = combinedRssList.Items.findIndex(
            (item) => item.songId === currentSong.songId
          );
        }

        setSong(combinedRssList.Items[currentSongIndex + 1], seriesData);
        setEpisodeList(combinedRssList);
      }
    }

    // console.log("episodeList", episodeList);
  };

  const tenSForward = () => {
    const currentTime = audioRef.current.currentTime + 10;
    audioRef.current.currentTime = currentTime;
  };

  const tenSBackward = () => {
    const currentTime = audioRef.current.currentTime - 10;
    audioRef.current.currentTime = currentTime;
  };

  const muteHandler = () => {
    if (muted) {
      audioRef.current.volume = 0.2;
      setVolume(20);
      setMuted(false);
    } else {
      audioRef.current.volume = 0;
      setVolume(0);
      setMuted(true);
    }
  };

  const updateFormattedDuration = () => {
    const duration = audioRef.current.duration;
    const minutes = Math.floor(duration / 60) || 0;
    const seconds = Math.floor(duration % 60) || 0;
    const formatted = `${minutes}:${seconds.toString().padStart(2, "0")}`;
    return formatted;
  };

  const updateFormattedCurrentTime = () => {
    // const currentTime = audioRef.current.currentTime;
    const minutes = Math.floor(currentTime / 60) || 0;
    const seconds = Math.floor(currentTime % 60) || 0;
    const formatted = `${minutes}:${seconds.toString().padStart(2, "0")} `;
    return formatted;
  };

  const contextValue = {
    audioRef,
    volume,
    setEpisodeList,
    isPlaying,
    setIsPlaying,
    currentSong,
    muted,
    seriesData,
    isLoading,
    setIsLoading,
    carouselName,
    setCarouselName,
    playPauseHandler,
    updateStreamCount,
    timelineSlideHandler,
    volumeChangeHandler,
    volumnChangeEvent,
    tenSForward,
    tenSBackward,
    muteHandler,
    currentTime,
    duration,
    progress,
    updateFormattedDuration,
    updateFormattedCurrentTime,
    setSong,
  };

  return (
    <PlayerContext.Provider value={contextValue}>
      <audio
        id="player"
        src={currentSong?.audio}
        ref={audioRef}
        onTimeUpdate={audioOnPlaying}
        onEnded={onEnd}
        onLoadStart={() => setIsLoading(true)}
        onError={() => setIsLoading(false)}
        onCanPlayThrough={() => setIsLoading(false)}
        onPause={() => setIsPlaying(false)}
        onPlaying={() => setIsPlaying(true)}
      />
      {children}
    </PlayerContext.Provider>
  );
};

// axios
//   .post(
//     "https://65bij2y2pl.execute-api.ap-south-1.amazonaws.com/test/api/audiolib/stream/data",
//     {
//       playback_url:
//         "https://d13x2ot3fgfff9.cloudfront.net/hls/d358fafe-784b-47c5-8b2c-060b4cc5362f.m3u8",
//     },
//     {
//       headers: {
//         Authorization: `Bearer ${authCtx.token}`,
//       },
//     }
//   )
//   .then(({ data }) => {
//     const { stream_key, stream_policy } = data;

//     const customHeaders = {
//       stream_key:
//         stream_key ||
//         "Gwbj+miSFF6E6ly6pZh29C0SRJ1CRdCfiy1mQLLvo+w=",
//       stream_policy:
//         stream_policy ||
//         "eyJ1cmwiOiIvaGxzLyoiLCJleHBpcmVzIjoxNzEwOTI1ODIyfQ==",
//     };
//     console.log(customHeaders);

//     // const tempAudio =
//     //   "https://d3nnblzleah2nw.cloudfront.net/PUBLISH_RECORD_SERIES/f009652e-1048-406b-b165-dee4c038f74c/e90ca8fd-0335-43ff-8661-dccb76836150/audio/hls/f78fac65-ec65-4ac4-ad9f-9a5da426f5b6.m3u8";
//     const tempAudioTest =
//       "https://d13x2ot3fgfff9.cloudfront.net/hls/d358fafe-784b-47c5-8b2c-060b4cc5362f.m3u8";

//     hls.config.xhrSetup = (xhr, url) => {
//       for (const header in customHeaders) {
//         xhr.setRequestHeader(header, customHeaders[header]);
//       }
//     };
//     hls.loadSource(tempAudioTest);
//     hls.attachMedia(playerRef);
//   });
