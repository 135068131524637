import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation } from "swiper/modules";
import { ReactComponent as CarouselBackArrow } from "../../assets/Images/carousel-back-arrow.svg";
import { ReactComponent as CarouselNextArrow } from "../../assets/Images/carousel-next-arrow.svg";
import ContinueListeningCard from "../Card/ContinueListeningCard";
import { useEffect, useRef, useState } from "react";
import { useAuthCtx } from "../../Context/AuthContext";
import { getContinueListening } from "../../apis";
const ContinueLiteningCarousel = () => {
  const [data, setData] = useState([]);
  const authCtx = useAuthCtx();
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const swiperRef = useRef(null);
  useEffect(() => {
    const fetchContinueListening = async () => {
      try {
        const resp = await getContinueListening(authCtx);
        setData(resp.data);
      } catch (err) {
        console.error("Error fetching continue listening data:", err);
      }
    };

    if (authCtx.token) {
      fetchContinueListening();
    }
  }, [authCtx.token]);

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.on("activeIndexChange", (e) => {
        const Index = e.realIndex;
        setActiveSlideIndex(Index);
      });
    }
  }, [swiperRef.current]);

  console.log(activeSlideIndex);

  if (data?.length === 0) {
    return;
  }

  return (
    <div className="carousel-container-wrapper">
      <div className="heading">
        <span>Continue Listening</span>
      </div>
      <Swiper
        grabCursor={true}
        loop={false}
        slidesPerView={"auto"}
        // slidesPerGroup={2}
        navigation={{
          nextEl: ".custom-next-button",
          prevEl: ".custom-prev-button",
          clickable: true,
        }}
        modules={[Navigation]}
        allowTouchMove={true}
        className={`carousel-container`}
      >
        {data &&
          data?.length >= 0 &&
          data?.map((publishCT, index) => (
            <SwiperSlide key={index}>
              <ContinueListeningCard key={index} publishCT={publishCT} />
            </SwiperSlide>
          ))}

        <div
          className={`custom-prev-button slider-arrow`}
          style={
            swiperRef?.current?.isBeginning
              ? { display: "none" }
              : { display: "block" }
          }
        >
          <CarouselBackArrow style={{ height: "100%", width: "100%" }} />
        </div>
        <div
          className="custom-next-button slider-arrow"
          style={
            swiperRef?.current?.isEnd
              ? { display: "none" }
              : { display: "block" }
          }
        >
          <CarouselNextArrow style={{ height: "100%", width: "100%" }} />
        </div>
      </Swiper>

      {/* <div className="divider"></div> */}
    </div>
  );
};

export default ContinueLiteningCarousel;
