import { majorVersion } from "./webDetails";
import mixpanel from "mixpanel-browser";
import { kinesis } from "../config/AwsConfig";
// import clevertap from "clevertap-web-sdk";
import secureLocalStorage from "react-secure-storage";

const AwsStreamName = process.env.REACT_APP_AWS_KINESIS_STREAM;
export const identyDevice = () => {
  let userAgent = navigator?.userAgent?.toLowerCase();
  if (userAgent?.includes("android")) {
    return "android-web";
  } else if (userAgent?.includes("iphone") || userAgent?.includes("ipad")) {
    return "iphone-web";
  } else {
    return "web";
  }
};

const BaseParameter = {
  clienttimestamp: new Date(),
  browserVersion: majorVersion,
  app_name: "eight-media-player",
  appVersion: 1,
  application: identyDevice() || "",
};

export const First_App_Open = (user) => {
  const parameter = {
    ...BaseParameter,
    user_id: user?.id,
  };

  // mixpanel.track("first_app_open", parameter);

  const KinesisParameter = {
    user_id: user?.id || secureLocalStorage.getItem("guestId"),
    userRegistered: user?.id ? true : false,
    application: identyDevice(),
    event: "first_app_open",
    event_time: Date.now(),
    properties: {
      ...parameter,
    },
  };

  const Kinesis_Event = {
    Data: JSON.stringify(KinesisParameter),
    PartitionKey: "first_app_open", // Use a unique identifier for partitioning
    StreamName: AwsStreamName,
  };

  kinesis.putRecord(Kinesis_Event, (err, data) => {
    if (err) {
      console.error("Error sending data to Kinesis:", err);
    } else {
    }
  });
  // clevertap.event.push("first_app_open", JSON.stringify(parameter));
};
export const APP_OPEN = (user) => {
  const parameter = {
    ...BaseParameter,
    user_id: user?.id,
  };

  // mixpanel.track("app_open_splash", parameter);

  const KinesisParameter = {
    user_id: user?.id || secureLocalStorage.getItem("guestId"),
    userRegistered: user?.id ? true : false,
    application: identyDevice(),
    event: "app_open_splash",
    event_time: Date.now(),
    properties: {
      ...parameter,
    },
  };
  // clevertap.event.push("app_open_splash", JSON.stringify(parameter));

  const Kinesis_Event = {
    Data: JSON.stringify(KinesisParameter),
    PartitionKey: "app_open_splash", // Use a unique identifier for partitioning
    StreamName: AwsStreamName,
  };

  kinesis.putRecord(Kinesis_Event, (err, data) => {
    if (err) {
      console.error("Error sending data to Kinesis:", err);
    } else {
    }
  });
};

export const SEND_OTP_EVENT = (user, phone) => {
  // mixpanel.track("send_otp", BaseParameter);

  // clevertap.event.push("send_otp", JSON.stringify(BaseParameter));

  const KinesisParameter = {
    user_id: user?.id || secureLocalStorage.getItem("guestId"),
    userRegistered: user?.id ? true : false,
    application: identyDevice(),
    event: "send_otp",
    event_time: Date.now(),
    properties: {
      ...BaseParameter,
      ...BaseParameter,
      user_name: user?.name,
      phone: phone,
    },
    gender: "",
    dob: "",
    dob_year: "",
  };

  const Kinesis_Event = {
    Data: JSON.stringify(KinesisParameter),
    PartitionKey: "send_otp",
    StreamName: AwsStreamName,
  };

  kinesis.putRecord(Kinesis_Event, (err, data) => {
    if (err) {
      console.error("Error sending data to Kinesis:", err);
    } else {
    }
  });
};

export const AUTHENTICATION_EVENT = (authentication_type = "google", user) => {
  const parameter = {
    ...BaseParameter,
    authentication_type: authentication_type,
  };

  // mixpanel.track("authentication", parameter);

  // clevertap.event.push("authentication", JSON.stringify(parameter));

  const KinesisParameter = {
    user_id: user?.id || secureLocalStorage.getItem("guestId"),
    userRegistered: user?.id ? true : false,
    application: identyDevice(),
    event: "authentication",
    event_time: Date.now(),
    properties: {
      ...BaseParameter,
      user_name: user?.name,
      authentication_type: authentication_type,
    },
    gender: "",
    dob: "",
    dob_year: "",
  };

  const Kinesis_Event = {
    Data: JSON.stringify(KinesisParameter),
    PartitionKey: "authentication",
    StreamName: AwsStreamName,
  };

  kinesis.putRecord(Kinesis_Event, (err, data) => {
    if (err) {
      console.error("Error sending data to Kinesis:", err);
    } else {
    }
  });
};

export const REGISTRATION = (user) => {
  const parameter = {
    ...BaseParameter,
    user_name: user?.name,
  };
  // mixpanel.track("registration", parameter);

  // clevertap.event.push("registration", JSON.stringify(parameter));

  const KinesisParameter = {
    user_id: user?.id || secureLocalStorage.getItem("guestId"),
    userRegistered: user?.id ? true : false,
    application: identyDevice(),
    event: "registration",
    event_time: Date.now(),
    properties: {
      ...BaseParameter,
      user_name: user?.name,
    },
    gender: "",
    dob: "",
    dob_year: "",
  };

  const Kinesis_Event = {
    Data: JSON.stringify(KinesisParameter),
    PartitionKey: "registration",
    StreamName: AwsStreamName,
  };

  kinesis.putRecord(Kinesis_Event, (err, data) => {
    if (err) {
      console.error("Error sending data to Kinesis:", err);
    } else {
    }
  });
};

export const SEARCH_ICON_CLICKED = (source, user) => {
  const parameter = {
    ...BaseParameter,
    source: source,
  };
  // mixpanel.track("search_icon_clicked", parameter);

  // clevertap.event.push("search_icon_clicked", JSON.stringify(parameter));

  const Kinesis_Event = {
    Data: JSON.stringify({ ...BaseParameter, source: source }),
    PartitionKey: "search_icon_clicked",
    StreamName: AwsStreamName,
  };

  kinesis.putRecord(Kinesis_Event, (err, data) => {
    if (err) {
    } else {
    }
  });
};

export const SEARCH_RESULT_SHOWN = (search_type, search_query, user) => {
  const parameter = {
    ...BaseParameter,
    search_query: search_query,
    search_type: search_type,
  };

  // mixpanel.track("search_result_show", parameter);

  // clevertap.event.push("search_result_show", JSON.stringify(parameter));

  const KinesisParameter = {
    user_id: user?.id || secureLocalStorage.getItem("guestId"),
    userRegistered: user?.id ? true : false,
    application: identyDevice(),
    event: "search_result_show",
    event_time: Date.now(),
    properties: {
      ...BaseParameter,
      search_type: search_type,
      search_query: search_query,
    },
    gender: "",
    dob: "",
    dob_year: "",
  };

  const Kinesis_Event = {
    Data: JSON.stringify(KinesisParameter),
    PartitionKey: "search_result_show",
    StreamName: AwsStreamName,
  };

  kinesis.putRecord(Kinesis_Event, (err, data) => {
    if (err) {
      console.error("Error sending data to Kinesis:", err);
    } else {
    }
  });
};

export const SEARCH_RESULT_CLICKED = (
  search_query,
  search_result_index,
  search_suggestion_clicked,
  search_type,
  user
) => {
  const parameter = {
    ...BaseParameter,
    search_query: search_query,
    search_result_index: search_result_index,
    search_suggestion_clicked: search_suggestion_clicked,
    search_type: search_type,
  };
  // clevertap.event.push("search_result_clicked", JSON.stringify(parameter));
  // mixpanel.track("search_result_clicked", parameter);

  const KinesisParameter = {
    user_id: user?.id || secureLocalStorage.getItem("guestId"),
    userRegistered: user?.id ? true : false,
    application: identyDevice(),
    event: "search_result_clicked",
    event_time: Date.now(),
    properties: {
      ...BaseParameter,
      search_type: search_type,
      search_query: search_query,
      search_result_index: search_result_index,
      search_suggestion_clicked: search_suggestion_clicked,
    },
    gender: "",
    dob: "",
    dob_year: "",
  };

  const Kinesis_Event = {
    Data: JSON.stringify(KinesisParameter),
    PartitionKey: "search_result_clicked",
    StreamName: AwsStreamName,
  };

  kinesis.putRecord(Kinesis_Event, (err, data) => {
    if (err) {
      console.error("Error sending data to Kinesis:", err);
    } else {
    }
  });
};

export const SEARCH_NO_RESULT = (search_query, search_type, user) => {
  const parameter = {
    ...BaseParameter,
    search_type: search_type,
    search_query: search_query,
  };
  // mixpanel.track("search_no_result", parameter);

  // clevertap.event.push("search_no_result", JSON.stringify(parameter));

  const KinesisParameter = {
    user_id: user?.id || secureLocalStorage.getItem("guestId"),
    userRegistered: user?.id ? true : false,
    application: identyDevice(),
    event: "search_no_result",
    event_time: Date.now(),
    properties: {
      ...BaseParameter,
      search_type: search_type,
      search_query: search_query,
    },
    gender: "",
    dob: "",
    dob_year: "",
  };

  const Kinesis_Event = {
    Data: JSON.stringify(KinesisParameter),
    PartitionKey: "search_no_result",
    StreamName: AwsStreamName,
  };

  kinesis.putRecord(Kinesis_Event, (err, data) => {
    if (err) {
      console.error("Error sending data to Kinesis:", err);
    } else {
    }
  });
};

export const PUBLISH_LIKED = (data, seriesData, user) => {
  const parameter = {
    ...BaseParameter,
    is_seeded: data?.is_seeded || "",
    content_name: data?.name || "",
    content_id: data?.songId || "",
    content_name_id: data?.songId,
    content_title: seriesData?.name || "",
    content_title_id: seriesData?.id || "",
    content_type: data?.contentType || "Series",
  };

  // mixpanel.track("published_liked", parameter);

  // clevertap.event.push("published_liked", JSON.stringify(parameter));

  const KinesisParameter = {
    user_id: user?.id || secureLocalStorage.getItem("guestId"),
    userRegistered: user?.id ? true : false,
    application: identyDevice(),
    event: "published_liked",
    event_time: Date.now(),
    properties: {
      ...BaseParameter,
      duration: parseInt(data?.currentTime),
      content_name_id: data?.songId,
      content_banner: data?.bannner,
      content_deeplink: data?.shortLink || "",
      content_name: data?.name,
      content_genre: JSON.stringify(data?.genre) || "",
      primaryGenre: data?.primaryGenre,
      serial_number: data?.serialNumber,
      content_title: seriesData?.name || "",
      content_title_id: seriesData?.id || "",
      content_type: data?.contentType || "Series",
      carouselName: "",
    },
    gender: "",
    dob: "",
    dob_year: "",
  };

  const Kinesis_Event = {
    Data: JSON.stringify(KinesisParameter),
    PartitionKey: "published_liked",
    StreamName: AwsStreamName,
  };

  kinesis.putRecord(Kinesis_Event, (err, data) => {
    if (err) {
      console.error("Error sending data to Kinesis:", err);
    } else {
    }
  });
};

export const PUBLISH_UNLIKED = (data, seriesData, user) => {
  const parameter = {
    ...BaseParameter,
    is_seeded: data?.is_seeded || "",
    content_name: data?.name || "",
    content_id: data?.songId || "",
    content_name_id: data?.songId || "",
    content_title: seriesData?.name || "",
    content_title_id: seriesData?.id || "",
    content_type: data?.content_type || "",
    serial_number: data?.serialNumber || "",
  };

  // mixpanel.track("published_unliked", parameter);

  // clevertap.event.push("published_unliked", JSON.stringify(parameter));

  const KinesisParameter = {
    user_id: user?.id || secureLocalStorage.getItem("guestId"),
    userRegistered: user?.id ? true : false,
    application: identyDevice(),
    event: "published_unliked",
    event_time: Date.now(),
    properties: {
      ...BaseParameter,
      duration: parseInt(data?.currentTime),
      content_name_id: data?.songId,
      content_banner: data?.bannner,
      content_deeplink: data?.shortLink || "",
      content_name: data?.name,
      content_genre: JSON.stringify(data?.genre) || "",
      primaryGenre: data?.primaryGenre,
      serial_number: data?.serialNumber,
      content_title: seriesData?.name || "",
      content_title_id: seriesData?.id || "",
      content_type: data?.contentType || "Series",
      carouselName: "",
    },
    gender: "",
    dob: "",
    dob_year: "",
  };

  const Kinesis_Event = {
    Data: JSON.stringify(KinesisParameter),
    PartitionKey: "published_unliked",
    StreamName: AwsStreamName,
  };

  kinesis.putRecord(Kinesis_Event, (err, data) => {
    if (err) {
      console.error("Error sending data to Kinesis:", err);
    } else {
    }
  });
};

export const SUBSCRIBE = (data, user, isSubscirbe) => {
  const parameter = {
    ...BaseParameter,
    is_seeded: data?.is_seeded || "",
    content_name: data?.name || "",
    content_id: data?.id || "",
    content_name_id: data?.id,
    subscribe: isSubscirbe,
    user_id: user?.id,
    content_deeplink: data?.shortLink || "",
    content_title: data?.name || "",
    content_type: data?.contentType || "Series",
    content_genre: JSON.stringify(data?.genre) || "",
  };

  // mixpanel.track("subscribe", parameter);

  // clevertap.event.push("subscribe", JSON.stringify(parameter));

  const KinesisParameter = {
    user_id: user?.id || secureLocalStorage.getItem("guestId"),
    userRegistered: user?.id ? true : false,
    application: identyDevice(),
    event: "subscribe",
    event_time: Date.now(),
    properties: {
      ...BaseParameter,
      duration: parseInt(data?.currentTime),
      content_name_id: data?.id,
      content_id: data?.id,
      content_banner: data?.bannner,
      content_deeplink: data?.shortLink || "",
      content_name: data?.name,
      content_genre: JSON.stringify(data?.genre) || "",
      primaryGenre: data?.primaryGenre,
      serial_number: data?.serialNumber,
      content_title: data?.title,
      content_type: data?.contentType || "Series",
      carouselName: "",
      subscribe: isSubscirbe,
    },
    gender: "",
    dob: "",
    dob_year: "",
  };

  const Kinesis_Event = {
    Data: JSON.stringify(KinesisParameter),
    PartitionKey: "subscribe",
    StreamName: AwsStreamName,
  };

  kinesis.putRecord(Kinesis_Event, (err, data) => {
    if (err) {
      console.error("Error sending data to Kinesis:", err);
    } else {
    }
  });
};

export const PUBLISH_CONTENT_ENTERED = (data, carouselName, user) => {
  const parameter = {
    ...BaseParameter,
    is_seeded: data?.is_seeded || "",
    content_name: data?.name || "",
    content_id: data?.id || "",
    content_name_id: data?.id,
    content_type: data?.contentType || "Series",
    content_title: data?.name || "",
    content_title_id: data?.id || "",
    content_genre: JSON.stringify(data?.genre) || "",
    user_id: data?.id || "",
    content_deeplink: data?.shortLink || "",
    carouselName: carouselName,
  };

  // mixpanel.track("published_content_entered", parameter);

  // clevertap.event.push("published_content_entered", JSON.stringify(parameter));

  const KinesisParameter = {
    user_id: user?.id || secureLocalStorage.getItem("guestId"),
    userRegistered: user?.id ? true : false,
    application: identyDevice(),
    event: "published_content_entered",
    event_time: Date.now(),
    properties: {
      ...BaseParameter,
      duration: parseInt(data?.currentTime),
      content_name_id: data?.id,
      content_id: data?.id || "",
      content_banner: data?.bannner,
      content_deeplink: data?.shortLink || "",
      content_name: data?.name,
      content_title: data?.name || "",
      content_genre: JSON.stringify(data?.genre) || "",
      primaryGenre: data?.primaryGenre,
      serial_number: data?.serialNumber,
      content_type: data?.contentType || "Series",
      carouselName: carouselName,
    },
    gender: "",
    dob: "",
    dob_year: "",
  };

  const Kinesis_Event = {
    Data: JSON.stringify(KinesisParameter),
    PartitionKey: "published_content_entered",
    StreamName: AwsStreamName,
  };
  kinesis.putRecord(Kinesis_Event, (err, data) => {
    if (err) {
      console.error("Error sending data to Kinesis:", err);
    } else {
    }
  });
};

export const PUBLISH_CONTENT_CLICKED = (
  data,
  seriesData,
  user,
  carouselName
) => {
  const parameter = {
    ...BaseParameter,
    is_seeded: data?.is_seeded || "",
    content_name: data?.name || "",
    content_banner: data?.banner || "",
    content_id: data?.songId || "",
    content_name_id: data?.songId,
    content_title: seriesData?.name || "",
    content_title_id: seriesData?.id || "",
    content_type: data?.content_type || "Series",
    user_id: user?.id || "",
    carouselName: carouselName,
    userRegistered: user?.id ? true : false,
    content_deeplink: data?.shortLink || "",
    content_genre: JSON.stringify(data?.genre) || "",
    primaryGenre: data?.primaryGenre,
  };

  // mixpanel.track("published_content_clicked", parameter);

  // clevertap.event.push("published_content_clicked", JSON.stringify(parameter));

  const KinesisParameter = {
    user_id: user?.id || secureLocalStorage.getItem("guestId"),
    userRegistered: user?.id ? true : false,
    application: identyDevice(),
    event: "published_content_clicked",
    event_time: Date.now(),
    properties: {
      ...BaseParameter,
      duration: parseInt(data?.currentTime),
      content_name_id: data?.id || data?.songId,
      content_banner: data?.bannner,
      content_deeplink: data?.shortLink || "",
      content_name: data?.name,
      content_genre: JSON.stringify(data?.genre) || "",
      primaryGenre: data?.primaryGenre,
      serial_number: data?.serialNumber,
      content_title: seriesData?.name || "",
      content_title_id: seriesData?.id || "",
      content_type: data?.content_type || "Series",
      carouselName: carouselName,
      userRegistered: user?.id ? true : false,
    },
    gender: "",
    dob: "",
    dob_year: "",
  };

  const Kinesis_Event = {
    Data: JSON.stringify(KinesisParameter),
    PartitionKey: "published_content_clicked",
    StreamName: AwsStreamName,
  };
  kinesis.putRecord(Kinesis_Event, (err, data) => {
    if (err) {
      console.error("Error sending data to Kinesis:", err);
    } else {
    }
  });
};

export const TUNED_IN = (data, seriesData, user, carouselName) => {
  const parameter = {
    ...BaseParameter,
    content_name: data?.name || "",
    content_id: data?.songId || "",
    content_name_id: data?.songId,
    content_banner: data?.banner || "",
    content_deeplink: data?.shortLink || "",
    language: data?.language || "Hindi",
    serial_number: data?.serialNumber || "",
    userId: user?.id || "NA",
    content_title: seriesData?.name || "",
    content_title_id: seriesData?.id || "",
    content_type: data?.contentType || "Series",
    content_genre: JSON.stringify(data?.genre) || "",
    carouselName: carouselName,
    user_id: user?.id || "",
    userRegistered: user?.id ? true : false,
    primaryGenre: data?.primaryGenre,
  };

  // mixpanel.track("tuned_in", parameter);

  // clevertap.event.push("tuned_in", JSON.stringify(parameter));

  const KinesisParameter = {
    user_id: user?.id || secureLocalStorage.getItem("guestId"),
    userRegistered: user?.id ? true : false,
    application: identyDevice(),
    event: "tuned_in",
    event_time: Date.now(),
    properties: {
      ...BaseParameter,
      duration: parseInt(data?.currentTime),
      content_name_id: data?.songId,
      content_banner: data?.bannner,
      content_deeplink: data?.shortLink || "",
      content_name: data?.name,
      content_genre: JSON.stringify(data?.genre) || "",
      primaryGenre: data?.primaryGenre,
      serial_number: data?.serialNumber,
      content_title: seriesData?.name || "",
      content_title_id: seriesData?.id || "",
      content_type: data?.content_type || "Series",
      carouselName: carouselName,
    },
    gender: "",
    dob: "",
    dob_year: "",
  };

  const Kinesis_Event = {
    Data: JSON.stringify(KinesisParameter),
    PartitionKey: "tuned_in",
    StreamName: AwsStreamName,
  };

  kinesis.putRecord(Kinesis_Event, (err, data) => {
    if (err) {
      console.error("Error sending data to Kinesis:", err);
    } else {
    }
  });
};

export const TUNED_OUT = (
  data,
  seriesData,
  user,
  leave_reason,
  carouselName
) => {
  const parameter = {
    ...BaseParameter,
    content_name: data?.name || "",
    content_id: data?.songId || "",
    content_name_id: data?.songId,
    content_banner: data?.banner || "",
    content_deeplink: data?.shortLink || "",
    serial_number: data?.serialNumber || "",
    language: data?.language || "Hindi",
    episode_listen_percentage: data?.progress || 0,
    duration: parseInt(data?.currentTime) || 0,
    content_title: seriesData?.name || "",
    content_title_id: seriesData?.id || "",
    content_type: data?.contentType || "Series",
    content_genre: JSON.stringify(data?.genre) || "",
    carouselName: carouselName,
    primaryGenre: data?.primaryGenre,
    userRegistered: user?.firstName ? true : false,
    user_id: user?.id || "",
  };

  // mixpanel.track("tuned_out", parameter);

  // clevertap.event.push("tuned_out", JSON.stringify(parameter));

  const KinesisParameter = {
    user_id: user?.id || secureLocalStorage.getItem("guestId"),
    userRegistered: user?.id ? true : false,
    application: identyDevice(),
    event: "tuned_out",
    event_time: Date.now(),
    properties: {
      ...BaseParameter,
      duration: parseInt(data?.currentTime) || 0,
      content_name_id: data?.songId,
      content_banner: data?.bannner,
      content_deeplink: data?.shortLink || "",
      content_name: data?.name,
      content_genre: JSON.stringify(data?.genre) || "",
      primaryGenre: data?.primaryGenre,
      serial_number: data?.serialNumber,
      content_title: seriesData?.name || "",
      content_title_id: seriesData?.id || "",
      content_type: data?.contentType || "Series",
      leave_reason: leave_reason,
      carouselName: carouselName,
      userRegistered: user?.firstName ? true : false,
      user_id: user?.id || "",
    },
    gender: "",
    dob: "",
    dob_year: "",
  };

  const Kinesis_Event = {
    Data: JSON.stringify(KinesisParameter),
    PartitionKey: "tuned_out",
    StreamName: AwsStreamName,
  };

  kinesis.putRecord(Kinesis_Event, (err, data) => {
    if (err) {
      console.error("Error sending data to Kinesis:", err);
    } else {
    }
  });
};

export const PUBLISH_CONTENT_ENDED = (
  data,
  seriesData,
  user,
  leave_reason,
  carouselName
) => {
  const parameter = {
    ...BaseParameter,
    is_seeded: data?.is_seeded || "",
    content_name: data?.name || "",
    content_title: seriesData?.name || data?.name,
    content_title_id: seriesData?.id,
    content_id: data?.songId || "",
    content_banner: data?.banner || "",
    content_deeplink: data?.shortLink || "",
    content_genre: JSON.stringify(data?.genre) || "",
    content_name_id: data?.songId || "",
    content_type: data?.content_type || "Series",
    af_content_type: data?.content_type || "Series",
    language: data?.language || "Hindi",
    serial_number: data?.serialNumber || "",
    duration: parseInt(data?.currentTime) || 0,
    leave_reason: leave_reason,
    carouselName: carouselName,
    primaryGenre: data?.primaryGenre,
    userRegistered: user?.firstName ? true : false,
    user_id: user?.id || "",
  };

  // mixpanel.track("published_content_ended", parameter);

  // clevertap.event.push("published_content_ended", JSON.stringify(parameter));

  const KinesisParameter = {
    user_id: user?.id || secureLocalStorage.getItem("guestId"),
    userRegistered: user?.id ? true : false,
    application: identyDevice(),
    event: "published_content_ended",
    event_time: Date.now(),
    properties: {
      ...BaseParameter,
      duration: parseInt(data?.currentTime) || 0,
      content_name_id: data?.songId,
      content_banner: data?.bannner,
      content_title: seriesData?.name || data?.name,
      content_title_id: seriesData?.id,
      content_deeplink: data?.shortLink || "",
      content_name: data?.name,
      content_genre: JSON.stringify(data?.genre) || "",
      primaryGenre: data?.primaryGenre,
      serial_number: data?.serialNumber,
      content_type: data?.contentType || "Series",
      leave_reason: leave_reason,
      carouselName: carouselName,
      userRegistered: user?.firstName ? true : false,
      user_id: user?.id || "",
    },
    gender: "",
    dob: "",
    dob_year: "",
  };

  const Kinesis_Event = {
    Data: JSON.stringify(KinesisParameter),
    PartitionKey: "published_content_ended",
    StreamName: AwsStreamName,
  };

  kinesis.putRecord(Kinesis_Event, (err, data) => {
    if (err) {
      console.error("Error sending data to Kinesis:", err);
    } else {
    }
  });
};
