import {
  Avatar,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
} from "@mui/material";

import { useContext, useEffect, useState } from "react";

import { ReactComponent as HomeIcon } from "../../assets/Images/home_icon.svg";
import { ReactComponent as SearchIcon } from "../../assets/Images/search-icon.svg";
import AuthContext from "../../Context/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import { loginModal } from "../Modal/Login/LoginModal";

const MobileBottomNavbar = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setValue(0);
        break;
      case "/search":
        setValue(1);
        break;
      default:
        setValue();
        break;
    }
  }, [location.pathname]);

  return (
    <div className="mobile-bottom-navigation">
      <Paper
        sx={{
          position: "fixed",
          bottom: "-2px",
          left: 0,
          right: 0,
          zIndex: 10000,
          background: "transparent",
        }}
        elevation={3}
      >
        <BottomNavigation
          sx={{
            width: "100%",
            color: "#8A8A8A",
            background: "rgba(33, 33, 33, 0.75)",
            backdropFilter: "blur(35px)",
            ".MuiBottomNavigationAction-root, .MuiButtonBase-root": {
              color: "#8A8A8A",
              textAlign: "center",
              fontFamily: "Roboto",
              fontSize: "1rem",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "130%",
              "&.Mui-selected": {
                color: "#F0F0F0",
                background:
                  "linear-gradient(90deg,rgba(217, 217, 217, 0) 0%,rgba(217, 217, 217, 0.1) 10%,rgba(217, 217, 217, 0.15) 20%,rgba(217, 217, 217, 0.2) 30%,rgba(217, 217, 217, 0.25) 40%,rgba(217, 217, 217, 0.25) 50%,rgba(217, 217, 217, 0.2) 60%,rgba(217, 217, 217, 0.15) 70%,rgba(217, 217, 217, 0.12) 80%,rgba(217, 217, 217, 0.05) 90%,rgba(217, 217, 217, 0.0) 100%)",
              },
            },
          }}
          showLabels
          value={value}
          onChange={(event, newValue) => {
            if (newValue === 0) {
              setValue(newValue);
              navigate("/");
            }
            if (newValue === 1) {
              setValue(newValue);
              navigate("/search");
            }
            if (newValue === 2) {
              if (!authCtx?.token?.length || authCtx?.token?.length < 1) {
                loginModal(true);
              }
            }
          }}
        >
          <BottomNavigationAction label="Home" icon={<HomeIcon />} />
          {/* <BottomNavigationAction label="Top Shows" icon={<TopShowsIcon />} /> */}
          <BottomNavigationAction label="Search" icon={<SearchIcon />} />
          <BottomNavigationAction
            label={`${authCtx?.token?.length > 1 ? "Profile" : "Sign Up"}`}
            icon={
              <Avatar
                src={authCtx?.user?.avatar}
                sx={{ height: "24px", width: "24px", pointerEvents: "none" }}
              />
            }
          />
        </BottomNavigation>
      </Paper>
    </div>
  );
};

export default MobileBottomNavbar;
