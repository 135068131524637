import React from "react";
import Image from "../../Ui/Image";
import { Link } from "react-router-dom";
import {
  categoryFormatter,
  contentNameFormtter,
  genreBreakFormatter,
} from "../../Util/FormatData/Formatter";

const GridViewSixCarouselCard = ({ arrayData, carouselName }) => {
  return (
    <div className="grid-view-six-carousel-card">
      {arrayData &&
        arrayData.map((item, index) => {
          const className =
            index === 0
              ? "main"
              : index === 1
              ? "img-1"
              : index === 2
              ? "img-2"
              : "";

          const link = `/${categoryFormatter(
            item?.style
          )}/${genreBreakFormatter(item?.primaryGenre)}/${contentNameFormtter(
            item?.name
          )}/${item?.id}`;

          return (
            <Link to={link} className={className} key={item.id}>
              <Image
                src={item?.bannerSquare?.md || item?.bannerSquare?.sm}
                alt={item?.banner1Alt || item?.about}
              />
            </Link>
          );
        })}
    </div>
  );
};

export default GridViewSixCarouselCard;
